<div class="container-fluid page-content">
  <div class="row page-middle no-gutters">
    <div class="col-12">
      <div class="page-header">
        <h1>{{ 'newOrganization' | i18n }}</h1>
      </div>
      <p>{{ 'newOrganizationDesc' | i18n }}</p>
      <app-organization-plans></app-organization-plans>
    </div>
  </div>
</div>
