<div class="tabbed-header d-flex">
  <h1>
    {{ 'paymentMethod' | i18n }}
  </h1>
  <button bitButton buttonType="secondary" (click)="load()" class="tw-ml-auto" *ngIf="firstLoaded" [disabled]="loading">
    <i class="bwi bwi-refresh bwi-fw" [ngClass]="{ 'bwi-spin': loading }" aria-hidden="true"></i>
    {{ 'refresh' | i18n }}
  </button>
</div>
<ng-container *ngIf="!firstLoaded && loading">
  <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
  <span class="sr-only">{{ 'loading' | i18n }}</span>
</ng-container>
<ng-container *ngIf="billing">
  <h2>{{ (isCreditBalance ? 'accountCredit' : 'accountBalance') | i18n }}</h2>
  <p class="text-lg">
    <strong>{{ creditOrBalance | currency: '$' }}</strong>
  </p>
  <p>{{ 'creditAppliedDesc' | i18n }}</p>
  <button bitButton buttonType="secondary" (click)="addCredit()" *ngIf="!showAddCredit">
    {{ 'addCredit' | i18n }}
  </button>
  <app-add-credit (onAdded)="closeAddCredit(true)" (onCanceled)="closeAddCredit(false)" *ngIf="showAddCredit"> </app-add-credit>
  <h2 class="spaced-header">{{ 'paymentMethod' | i18n }}</h2>
  <p *ngIf="!paymentSource">{{ 'noPaymentMethod' | i18n }}</p>
  <ng-container *ngIf="paymentSource">
    <p>
      <i
        class="bwi bwi-fw"
        [ngClass]="{
          'bwi-credit-card': paymentSource.type === paymentMethodType.Card,
          'bwi-bank': paymentSource.type === paymentMethodType.BankAccount,
          'bwi-money': paymentSource.type === paymentMethodType.Check,
          'bwi-paypal text-primary': paymentSource.type === paymentMethodType.PayPal,
          'bwi-apple text-muted': paymentSource.type === paymentMethodType.AppleInApp,
          'bwi-google text-muted': paymentSource.type === paymentMethodType.GoogleInApp
        }"
      ></i>
      <span *ngIf="paymentSourceInApp">{{ 'inAppPurchase' | i18n }}</span>
      {{ paymentSource.description }}
    </p>
  </ng-container>
  <button bitButton buttonType="secondary" (click)="changePayment()" *ngIf="!showAdjustPayment">
    {{ (paymentSource ? 'changePaymentMethod' : 'addPaymentMethod') | i18n }}
  </button>
  <app-adjust-payment
    [currentType]="paymentSource != null ? paymentSource.type : null"
    (onAdjusted)="closePayment(true)"
    (onCanceled)="closePayment(false)"
    *ngIf="showAdjustPayment"
  >
  </app-adjust-payment>
</ng-container>
