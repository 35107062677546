<div class="icon" aria-hidden="true">
  <img
    [src]="image"
    appFallbackSrc="{{ fallbackImage }}"
    *ngIf="imageEnabled && image"
    alt=""
    decoding="async"
    loading="lazy"
  />
  <i class="{{ icon }}" *ngIf="icon && icon.indexOf('globe')!==-1 && (!imageEnabled || !image)"></i>
  <i class="bwi bwi-fw bwi-lg {{ icon }}" *ngIf="icon && icon.indexOf('globe')===-1 && (!imageEnabled || !image)"></i>
</div>
