/* 🤖 this file was generated by svg-to-ts*/

import { MyIcon } from './svg/my-icons.model'

export type MyIconNameSubset<T extends Readonly<MyIcon[]>> = T[number]['name']

export * from './svg/app-cog-su.icon'
export * from './svg/app-cross-su.icon'
export * from './svg/app-lock-su.icon'
export * from './svg/app-otp-su.icon'
export * from './svg/app-search-su.icon'
export * from './svg/app-tools-su.icon'
export * from './svg/app-user-su.icon'
export * from './svg/my-icons.model'
