<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" class="container" ngNativeValidate>
  <div class="row justify-content-md-center mt-5">
    <div class="col-6">
      <p class="lead text-center mb-4">{{ 'passwordHint' | i18n }}</p>
      <div class="card d-block">
        <div class="card-body">
          <div class="form-group">
            <label for="email">{{ 'emailAddress' | i18n }}</label>
            <input
              id="email"
              class="form-control"
              type="text"
              name="Email"
              [(ngModel)]="email"
              required
              appAutofocus
              inputmode="email"
              appInputVerbatim="false"
            />
            <small class="form-text text-muted">{{ 'enterEmailToGetHint' | i18n }}</small>
          </div>
          <hr />
          <div class="d-flex">
            <button type="submit" class="btn btn-primary btn-block btn-submit" [disabled]="form.loading">
              <span [hidden]="form.loading">{{ 'submit' | i18n }}</span>
              <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
            </button>
            <a routerLink="/login" class="btn btn-outline-secondary btn-block ml-1 mt-0">
              {{ 'cancel' | i18n }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
