<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="groupAddEditTitle">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
      <div class="modal-header">
        <h2 class="modal-title" id="groupAddEditTitle">{{ title }}</h2>
        <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{ 'close' | i18n }}">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="loading">
        <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
        <span class="sr-only">{{ 'loading' | i18n }}</span>
      </div>
      <div class="modal-body" *ngIf="!loading">
        <div class="form-group">
          <label for="name">{{ 'name' | i18n }}</label>
          <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="name" required />
        </div>
        <div class="form-group">
          <label for="externalId">{{ 'externalId' | i18n }}</label>
          <input id="externalId" class="form-control" type="text" name="ExternalId" [(ngModel)]="externalId" />
          <small class="form-text text-muted">{{ 'externalIdDesc' | i18n }}</small>
        </div>
        <h3 class="mt-4 d-flex">
          <div class="mb-2">
            {{ 'accessControl' | i18n }}
            <a
              target="_blank"
              rel="noopener"
              appA11yTitle="{{ 'learnMore' | i18n }}"
              href="https://bitwarden.com/help/user-types-access-control/#access-control"
            >
              <i class="fa fa-question-circle-o" aria-hidden="true"></i>
            </a>
          </div>
          <div class="ml-auto" *ngIf="access === 'selected' && collections && collections.length">
            <button type="button" (click)="selectAll(true)" class="btn btn-link btn-sm py-0">
              {{ 'selectAll' | i18n }}
            </button>
            <button type="button" (click)="selectAll(false)" class="btn btn-link btn-sm py-0">
              {{ 'unselectAll' | i18n }}
            </button>
          </div>
        </h3>
        <div class="form-group" [ngClass]="{ 'mb-0': access !== 'selected' }">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="access" id="accessAll" value="all" [(ngModel)]="access" />
            <label class="form-check-label" for="accessAll">
              {{ 'groupAccessAllItems' | i18n }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="access" id="accessSelected" value="selected" [(ngModel)]="access" />
            <label class="form-check-label" for="accessSelected">
              {{ 'groupAccessSelectedCollections' | i18n }}
            </label>
          </div>
        </div>
        <ng-container *ngIf="access === 'selected'">
          <div *ngIf="!collections || !collections.length">
            {{ 'noCollectionsInList' | i18n }}
          </div>
          <table class="table table-hover table-list mb-0" *ngIf="collections && collections.length">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>{{ 'name' | i18n }}</th>
                <th width="100" class="text-center">{{ 'hidePasswords' | i18n }}</th>
                <th width="100" class="text-center">{{ 'readOnly' | i18n }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of collections; let i = index">
                <td class="table-list-checkbox" (click)="check(c)">
                  <input type="checkbox" [(ngModel)]="c.checked" name="Collection[{{ i }}].Checked" appStopProp />
                </td>
                <td (click)="check(c)">
                  {{ c.name }}
                </td>
                <td class="text-center">
                  <input type="checkbox" [(ngModel)]="c.hidePasswords" name="Collection[{{ i }}].HidePasswords" [disabled]="!c.checked" />
                </td>
                <td class="text-center">
                  <input type="checkbox" [(ngModel)]="c.readOnly" name="Collection[{{ i }}].ReadOnly" [disabled]="!c.checked" />
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ 'save' | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ 'cancel' | i18n }}
        </button>
        <div class="ml-auto">
          <button
            #deleteBtn
            type="button"
            (click)="delete()"
            class="btn btn-outline-danger"
            appA11yTitle="{{ 'delete' | i18n }}"
            *ngIf="editMode"
            [disabled]="deleteBtn.loading"
            [appApiAction]="deletePromise"
          >
            <i class="bwi bwi-trash bwi-lg bwi-fw" [hidden]="deleteBtn.loading" aria-hidden="true"></i>
            <i class="bwi bwi-spinner bwi-spin bwi-lg bwi-fw" [hidden]="!deleteBtn.loading" aria-hidden="true" title="{{ 'loading' | i18n }}"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
