<ng-container *ngIf="!hide">
  <!--  <div class="filter-heading">-->
  <!--    <button-->
  <!--      class="toggle-button"-->
  <!--      (click)="toggleCollapse(foldersGrouping)"-->
  <!--      [attr.aria-expanded]="!isCollapsed(foldersGrouping)"-->
  <!--      aria-controls="folder-filters"-->
  <!--      title="{{ 'toggleCollapse' | i18n }}"-->
  <!--    >-->
  <!--      <i-->
  <!--        class="bwi bwi-fw"-->
  <!--        aria-hidden="true"-->
  <!--        [ngClass]="{-->
  <!--          'bwi-angle-right': isCollapsed(foldersGrouping),-->
  <!--          'bwi-angle-down': !isCollapsed(foldersGrouping)-->
  <!--        }"-->
  <!--      ></i>-->
  <!--    </button>-->
  <!--    <h3 class="filter-title">&nbsp;{{ 'folders' | i18n }}</h3>-->
  <!--    <button class="text-muted ml-auto add-button" (click)="addFolder()" appA11yTitle="{{ 'addFolder' | i18n }}">-->
  <!--      <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>-->
  <!--    </button>-->
  <!--  </div>-->
  <h3 class="d-flex">
    {{ 'folders' | i18n }}
    <a href="#" class="text-muted ml-auto" appStopClick (click)="addFolder()" appA11yTitle="{{ 'addFolder' | i18n }}">
      <i class="fa fa-plus fa-fw fa-plus-thin" aria-hidden="true"></i>
    </a>
  </h3>
  <ul id="folder-filters" *ngIf="!isCollapsed(foldersGrouping)" class="filter-options card-ul">
    <ng-template #recursiveFolders let-folders>
      <li
        *ngFor="let f of folders"
        [ngClass]="{
          active: f.node.id === activeFilter.selectedFolderId && activeFilter.selectedFolder
        }"
        class="filter-option"
      >
        <a
          href="#"
          appStopClick
          *ngIf="f.children.length"
          title="{{ 'toggleCollapse' | i18n }}"
          (click)="toggleCollapse(f.node)"
          [attr.aria-expanded]="!isCollapsed(f.node)"
          [attr.aria-controls]="f.node.name + '_children'"
          class="toggle-button"
        >
          <i
            class="bwi bwi-fw"
            [ngClass]="{
              'bwi-angle-right': isCollapsed(f.node),
              'bwi-angle-down': !isCollapsed(f.node)
            }"
            aria-hidden="true"
          ></i>
        </a>
        <a href="#" appStopClick class="filter-button" (click)="applyFilter(f.node)">
          <i *ngIf="f.children.length === 0" class="fa fa-fw fa-folder-open" aria-hidden="true"></i>&nbsp;{{ f.node.name }}
        </a>
        <a
          *ngIf="f.node.name !== 'Aucun dossier'"
          href="#"
          appStopClick
          class="edit-button li-control"
          (click)="editFolder(f.node)"
          appA11yTitle="{{ 'editFolder' | i18n }}"
        >
          <i class="fa fa-pencil fa-fw" aria-hidden="true"></i>
        </a>
        <ul [id]="f.node.name + '_children'" class="nested-filter-options" *ngIf="f.children.length && !isCollapsed(f.node)">
          <ng-container *ngTemplateOutlet="recursiveFolders; context: { $implicit: f.children }"></ng-container>
        </ul>
      </li>
    </ng-template>
    <ng-container *ngTemplateOutlet="recursiveFolders; context: { $implicit: nestedFolders }"></ng-container>
  </ul>
</ng-container>
