import { VaultFilter } from 'jslib-angular/modules/vault-filter/models/vault-filter.model'

export class VaultService {
  calculateSearchBarLocalizationString(vaultFilter: VaultFilter): string {
    if (vaultFilter.status === 'favorites') {
      return 'searchFavorites'
    }
    if (vaultFilter.status === 'trash') {
      return 'searchTrash'
    }
    if (vaultFilter.cipherType != null) {
      return 'searchType'
    }
    if (vaultFilter.selectedFolderId != null && vaultFilter.selectedFolderId != 'none') {
      return 'searchFolder'
    }
    if (vaultFilter.selectedCollectionId != null) {
      return 'searchCollection'
    }
    if (vaultFilter.selectedOrganizationId != null) {
      return 'searchOrganization'
    }
    if (vaultFilter.myVaultOnly) {
      return 'searchMyVault'
    }

    return 'searchVault'
  }
}
