<ng-container *ngIf="show">
  <ul class="filter-options card-ul">
    <li class="filter-option" [ngClass]="{ active: activeFilter.status === 'all' }">
      <a href="#" appStopClick class="filter-button" (click)="applyFilter('all')">
        <i class="fa fa-fw fa-th" aria-hidden="true"></i>&nbsp;{{ 'allItems' | i18n }}
      </a>
    </li>
    <li *ngIf="!hideFavorites" class="filter-option" [ngClass]="{ active: activeFilter.status === 'favorites' }">
      <a href="#" appStopClick class="filter-button" (click)="applyFilter('favorites')">
        <i class="fa fa-fw fa-star" aria-hidden="true"></i>&nbsp;{{ 'favorites' | i18n }}
      </a>
    </li>
    <li *ngIf="!hideTrash" class="filter-option" [ngClass]="{ active: activeFilter.status === 'trash' }">
      <a href="#" appStopClick class="filter-button" (click)="applyFilter('trash')">
        <i class="fa fa-fw fa-trash-o" aria-hidden="true"></i>&nbsp;{{ 'trash' | i18n }}
      </a>
    </li>
  </ul>
</ng-container>
