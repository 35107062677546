<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
  <div>
    <div class="row">
      <div class="form-group col-6">
        <label for="newSeatCount">{{ 'subscriptionSeats' | i18n }}</label>
        <input id="newSeatCount" class="form-control" type="number" name="NewSeatCount" [(ngModel)]="newSeatCount" min="0" step="1" required />
        <small class="d-block text-muted mb-4">
          <strong>{{ 'total' | i18n }}:</strong> {{ newSeatCount || 0 }} &times; {{ seatPrice | currency: '$' }} =
          {{ adjustedSeatTotal | currency: '$' }} /
          {{ interval | i18n }}
        </small>
      </div>
    </div>
    <div class="row mb-4">
      <div class="form-group col-sm">
        <div class="form-check">
          <input
            id="limitSubscription"
            class="form-check-input"
            type="checkbox"
            name="LimitSubscription"
            [(ngModel)]="limitSubscription"
            (change)="limitSubscriptionChanged()"
          />
          <label for="limitSubscription">{{ 'limitSubscription' | i18n }}</label>
        </div>
        <small class="d-block text-muted">{{ 'limitSubscriptionDesc' | i18n }}</small>
      </div>
    </div>
    <div class="row mb-4" [hidden]="!limitSubscription">
      <div class="form-group col-sm">
        <label for="maxAutoscaleSeats">{{ 'maxSeatLimit' | i18n }}</label>
        <input
          id="maxAutoscaleSeats"
          class="form-control col-6"
          type="number"
          name="MaxAutoscaleSeats"
          [(ngModel)]="newMaxSeats"
          [min]="newSeatCount == null ? 1 : newSeatCount"
          step="1"
          [required]="limitSubscription"
        />
        <small class="d-block text-muted">
          <strong>{{ 'maxSeatCost' | i18n }}:</strong> {{ newMaxSeats || 0 }} &times; {{ seatPrice | currency: '$' }} =
          {{ maxSeatTotal | currency: '$' }} /
          {{ interval | i18n }}
        </small>
      </div>
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ 'save' | i18n }}</span>
    </button>
  </div>
</form>
<app-payment [showMethods]="false"></app-payment>
