<form #form class="card" (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
  <div class="card-body">
    <button type="button" class="close" appA11yTitle="{{ 'cancel' | i18n }}" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="card-body-header">
      {{ (currentType != null ? 'changePaymentMethod' : 'addPaymentMethod') | i18n }}
    </h3>
    <app-payment [hideBank]="!organizationId" [hideCredit]="true"></app-payment>
    <app-tax-info (onCountryChanged)="changeCountry()"></app-tax-info>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ 'submit' | i18n }}</span>
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
      {{ 'cancel' | i18n }}
    </button>
  </div>
</form>
