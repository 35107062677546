import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { HintComponent as BaseHintComponent } from 'jslib-angular/components/hint.component'
import { ApiService } from 'jslib-common/abstractions/api.service'
import { I18nService } from 'jslib-common/abstractions/i18n.service'
import { LogService } from 'jslib-common/abstractions/log.service'
import { PlatformUtilsService } from 'jslib-common/abstractions/platformUtils.service'

@Component({
  selector: 'app-hint',
  templateUrl: 'hint.component.html',
})
export class HintComponent extends BaseHintComponent {
  constructor(router: Router, i18nService: I18nService, apiService: ApiService, platformUtilsService: PlatformUtilsService, logService: LogService) {
    super(router, i18nService, apiService, platformUtilsService, logService)
  }
}
