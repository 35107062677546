import { Directive, Input, OnInit, ElementRef, OnDestroy, SimpleChanges } from '@angular/core'
import tippy, { followCursor, Instance, Props } from 'tippy.js'

@Directive({
  selector: '[tippy]',
})
export class TippyDirective implements OnInit, OnDestroy {
  @Input('tippyOptions') tippyOptions: any
  @Input('tippy') title: string
  private instance: Instance

  constructor(private el: ElementRef) {
    this.el = el
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title && this.instance) {
      this.instance.setContent(changes.title.currentValue)
    }
  }

  ngOnInit() {
    const options: any = this.tippyOptions || {}

    if (this.title) {
      options.content = this.title
    }

    if (!options.content) {
      options.content = this.el.nativeElement.getAttribute('title')
    }

    this.instance = (tippy as any)(this.el.nativeElement, options) as Instance<Props>
  }

  ngOnDestroy() {
    this.instance.destroy()
  }
}
