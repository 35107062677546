import { NgModule } from '@angular/core'

import { UserService } from '../../../../jslib/angular/src/services/user.service'
import {
  CollectionService as CollectionServiceAbstraction
} from "../../../../jslib/common/src/abstractions/collection.service";
import {CryptoService as CryptoServiceAbstraction} from "../../../../jslib/common/src/abstractions/crypto.service";
import {I18nService as I18nServiceAbstraction} from "../../../../jslib/common/src/abstractions/i18n.service";
import {StateService as StateServiceAbstraction} from "../../../../jslib/common/src/abstractions/state.service";
import {CollectionService} from "../../../../jslib/common/src/services/collection.service";
import { LooseComponentsModule } from '../loose-components.module'
import { SharedModule } from '../shared.module'
import { VaultFilterModule } from '../vault-filter/vault-filter.module'

import { VaultService } from './vault.service'

@NgModule({
  imports: [SharedModule, VaultFilterModule, LooseComponentsModule],
  exports: [SharedModule, VaultFilterModule, LooseComponentsModule],
  providers: [
    {
      provide: VaultService,
      useClass: VaultService,
    },
    {
      provide: UserService,
      useClass: UserService,
    },
    {
      provide: CollectionServiceAbstraction,
      useClass: CollectionService,
      deps: [CryptoServiceAbstraction, I18nServiceAbstraction, StateServiceAbstraction],
    },
  ],
})
export class VaultModule {}
