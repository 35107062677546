<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="confirmUserTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()">
      <div class="modal-header">
        <h2 class="modal-title" id="confirmUserTitle">
          {{ 'passwordConfirmation' | i18n }}
        </h2>
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ 'passwordConfirmationDesc' | i18n }}

        <div class="form-group">
          <label for="masterPassword">{{ 'masterPass' | i18n }}</label>
          <div class="d-flex">
            <input
              id="masterPassword"
              type="{{ showPassword ? 'text' : 'password' }}"
              name="MasterPassword"
              class="text-monospace form-control"
              [(ngModel)]="masterPassword"
              required
              appAutofocus
              appInputVerbatim
            />
            <button type="button" class="ml-1 btn btn-link" appA11yTitle="{{ 'toggleVisibility' | i18n }}" (click)="togglePassword()">
              <i class="bwi bwi-lg" aria-hidden="true" [ngClass]="{ 'bwi-eye': !showPassword, 'bwi-eye-slash': showPassword }"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" appBlurClick>
          <span>{{ 'ok' | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ 'cancel' | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
