<app-change-kdf *ngIf="showChangeKdf"></app-change-kdf>
<div [ngClass]="{ 'tabbed-header': !showChangeKdf, 'secondary-header': showChangeKdf }" class="border-0 mb-0">
  <h1>{{ 'apiKey' | i18n }}</h1>
</div>
<p>
  {{ 'userApiKeyDesc' | i18n }}
</p>
<button bitButton buttonType="secondary" (click)="viewUserApiKey()">
  {{ 'viewApiKey' | i18n }}
</button>
<button bitButton buttonType="secondary" (click)="rotateUserApiKey()">
  {{ 'rotateApiKey' | i18n }}
</button>
<ng-template #viewUserApiKeyTemplate></ng-template>
<ng-template #rotateUserApiKeyTemplate></ng-template>
