<div class="page-header d-flex">
  <h1>
    {{ 'billing' | i18n }}
  </h1>
  <button (click)="load()" class="btn btn-sm btn-outline-primary ml-auto" *ngIf="firstLoaded" [disabled]="loading">
    <i class="bwi bwi-refresh bwi-fw" [ngClass]="{ 'bwi-spin': loading }" aria-hidden="true"></i>
    {{ 'refresh' | i18n }}
  </button>
</div>
<ng-container *ngIf="!firstLoaded && loading">
  <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
  <span class="sr-only">{{ 'loading' | i18n }}</span>
</ng-container>
<ng-container *ngIf="billing">
  <h2>{{ (isCreditBalance ? 'accountCredit' : 'accountBalance') | i18n }}</h2>
  <p class="text-lg">
    <strong>{{ creditOrBalance | currency: '$' }}</strong>
  </p>
  <p>{{ 'creditAppliedDesc' | i18n }}</p>
  <button type="button" class="btn btn-outline-secondary" (click)="addCredit()" *ngIf="!showAddCredit">
    {{ 'addCredit' | i18n }}
  </button>
  <app-add-credit [organizationId]="organizationId" (onAdded)="closeAddCredit(true)" (onCanceled)="closeAddCredit(false)" *ngIf="showAddCredit">
  </app-add-credit>
  <h2 class="spaced-header">{{ 'paymentMethod' | i18n }}</h2>
  <p *ngIf="!paymentSource">{{ 'noPaymentMethod' | i18n }}</p>
  <ng-container *ngIf="paymentSource">
    <app-callout
      type="warning"
      title="{{ 'verifyBankAccount' | i18n }}"
      *ngIf="paymentSource.type === paymentMethodType.BankAccount && paymentSource.needsVerification"
    >
      <p>{{ 'verifyBankAccountDesc' | i18n }} {{ 'verifyBankAccountFailureWarning' | i18n }}</p>
      <form #verifyForm class="form-inline" (ngSubmit)="verifyBank()" [appApiAction]="verifyBankPromise" ngNativeValidate>
        <label class="sr-only" for="verifyAmount1">{{ 'amount' | i18n: '1' }}</label>
        <div class="input-group mr-2">
          <div class="input-group-prepend">
            <div class="input-group-text">$0.</div>
          </div>
          <input
            type="number"
            class="form-control"
            id="verifyAmount1"
            placeholder="xx"
            name="Amount1"
            [(ngModel)]="verifyAmount1"
            min="1"
            max="99"
            step="1"
            required
          />
        </div>
        <label class="sr-only" for="verifyAmount2">{{ 'amount' | i18n: '2' }}</label>
        <div class="input-group mr-2">
          <div class="input-group-prepend">
            <div class="input-group-text">$0.</div>
          </div>
          <input
            type="number"
            class="form-control"
            id="verifyAmount2"
            placeholder="xx"
            name="Amount2"
            [(ngModel)]="verifyAmount2"
            min="1"
            max="99"
            step="1"
            required
          />
        </div>
        <button type="submit" class="btn btn-outline-primary btn-submit" [disabled]="verifyForm.loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ 'verifyBankAccount' | i18n }}</span>
        </button>
      </form>
    </app-callout>
    <p>
      <i
        class="bwi bwi-fw"
        [ngClass]="{
          'bwi-credit-card': paymentSource.type === paymentMethodType.Card,
          'bwi-bank': paymentSource.type === paymentMethodType.BankAccount,
          'bwi-money': paymentSource.type === paymentMethodType.Check,
          'bwi-paypal text-primary': paymentSource.type === paymentMethodType.PayPal,
          'bwi-apple text-muted': paymentSource.type === paymentMethodType.AppleInApp,
          'bwi-google text-muted': paymentSource.type === paymentMethodType.GoogleInApp
        }"
      ></i>
      <span *ngIf="paymentSourceInApp">{{ 'inAppPurchase' | i18n }}</span>
      {{ paymentSource.description }}
    </p>
  </ng-container>
  <button type="button" class="btn btn-outline-secondary" (click)="changePayment()" *ngIf="!showAdjustPayment">
    {{ (paymentSource ? 'changePaymentMethod' : 'addPaymentMethod') | i18n }}
  </button>
  <app-adjust-payment
    [currentType]="paymentSource != null ? paymentSource.type : null"
    [organizationId]="organizationId"
    (onAdjusted)="closePayment(true)"
    (onCanceled)="closePayment(false)"
    *ngIf="showAdjustPayment"
  >
  </app-adjust-payment>
  <h2 class="spaced-header">{{ 'invoices' | i18n }}</h2>
  <p *ngIf="!invoices || !invoices.length">{{ 'noInvoices' | i18n }}</p>
  <table class="table mb-2" *ngIf="invoices && invoices.length">
    <tbody>
      <tr *ngFor="let i of invoices">
        <td>{{ i.date | date: 'mediumDate' }}</td>
        <td>
          <a href="{{ i.pdfUrl }}" target="_blank" rel="noopener" class="mr-2" appA11yTitle="{{ 'downloadInvoice' | i18n }}">
            <i class="bwi bwi-file-pdf" aria-hidden="true"></i
          ></a>
          <a href="{{ i.url }}" target="_blank" rel="noopener" title="{{ 'viewInvoice' | i18n }}"> {{ 'invoiceNumber' | i18n: i.number }}</a>
        </td>
        <td>{{ i.amount | currency: '$' }}</td>
        <td>
          <span *ngIf="i.paid">
            <i class="bwi bwi-check text-success" aria-hidden="true"></i>
            {{ 'paid' | i18n }}
          </span>
          <span *ngIf="!i.paid">
            <i class="bwi bwi-exclamation-circle text-muted" aria-hidden="true"></i>
            {{ 'unpaid' | i18n }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <h2 class="spaced-header">{{ 'transactions' | i18n }}</h2>
  <p *ngIf="!transactions || !transactions.length">{{ 'noTransactions' | i18n }}</p>
  <table class="table mb-2" *ngIf="transactions && transactions.length">
    <tbody>
      <tr *ngFor="let t of transactions">
        <td>{{ t.createdDate | date: 'mediumDate' }}</td>
        <td>
          <span *ngIf="t.type === transactionType.Charge || t.type === transactionType.Credit">
            {{ 'chargeNoun' | i18n }}
          </span>
          <span *ngIf="t.type === transactionType.Refund">{{ 'refundNoun' | i18n }}</span>
        </td>
        <td>
          <i
            class="bwi bwi-fw"
            *ngIf="t.paymentMethodType"
            aria-hidden="true"
            [ngClass]="{
              'bwi-credit-card': t.paymentMethodType === paymentMethodType.Card,
              'bwi-bank': t.paymentMethodType === paymentMethodType.BankAccount || t.paymentMethodType === paymentMethodType.WireTransfer,
              'bwi-bitcoin text-warning': t.paymentMethodType === paymentMethodType.BitPay,
              'bwi-paypal text-primary': t.paymentMethodType === paymentMethodType.PayPal
            }"
          ></i>
          {{ t.details }}
        </td>
        <td [ngClass]="{ 'text-strike': t.refunded }" title="{{ (t.refunded ? 'refunded' : '') | i18n }}">
          {{ t.amount | currency: '$' }}
        </td>
      </tr>
    </tbody>
  </table>
  <small class="text-muted">* {{ 'chargesStatement' | i18n: 'BITWARDEN' }}</small>
</ng-container>
