<app-callout type="warning">
  {{ 'sendOptionsExemption' | i18n }}
</app-callout>

<div class="form-group">
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="enabled" [formControl]="enabled" name="Enabled" />
    <label class="form-check-label" for="enabled">{{ 'enabled' | i18n }}</label>
  </div>
</div>

<div [formGroup]="data">
  <h3 class="mt-4">{{ 'options' | i18n }}</h3>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="disableHideEmail" name="DisableHideEmail" formControlName="disableHideEmail" />
    <label class="form-check-label" for="disableHideEmail">{{ 'disableHideEmail' | i18n }}</label>
  </div>
</div>
