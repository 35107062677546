<div class="container-fluid page-content">
  <div class="row page-row no-gutters">
    <div class="col-3 page-left">
      <app-vault-filter
        class="card -flat vault-filters"
        #vaultFilter
        [activeFilter]="activeFilter"
        (onFilterChange)="applyVaultFilter($event)"
        (onAddFolder)="addFolder()"
        (onEditFolder)="editFolder($event.id)"
        (onSearchTextChanged)="filterSearchText($event)"
      ></app-vault-filter>
    </div>
    <div [class]="'col-6 page-middle page-sub-content'">
      <div class="page-header d-flex">
        <h1>
          {{ 'myVault' | i18n }}
          <small #actionSpinner [appApiAction]="ciphersComponent.actionPromise">
            <ng-container *ngIf="actionSpinner.loading">
              <i class="fa fa-spinner fa-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
              <span class="sr-only">{{ 'loading' | i18n }}</span>
            </ng-container>
          </small>
        </h1>
        <div class="ml-auto d-flex">
          <app-vault-bulk-actions [ciphersComponent]="ciphersComponent" [deleted]="activeFilter.status === 'trash'"></app-vault-bulk-actions>
          <button type="button" class="btn btn-text" (click)="addCipher()" *ngIf="activeFilter.status !== 'trash'">
            <i class="fa fa-plus fa-fw" aria-hidden="true"></i>{{ 'addItem' | i18n }}
          </button>
        </div>
      </div>
      <app-callout type="warning" *ngIf="activeFilter.status === 'trash'" icon="bwi-exclamation-triangle">
        {{ trashCleanupWarning }}
      </app-callout>
      <app-vault-ciphers
        (onCipherClicked)="editCipher($event)"
        (onAttachmentsClicked)="editCipherAttachments($event)"
        (onAddCipher)="addCipher()"
        (onShareClicked)="shareCipher($event)"
        (onCollectionsClicked)="editCipherCollections($event)"
        (onCloneClicked)="cloneCipher($event)"
        (onOrganzationBadgeClicked)="applyOrganizationFilter($event)"
      >
      </app-vault-ciphers>
    </div>
    <div class="col-3 page-right page-middle">
      <div class="card border-warning mb-4" *ngIf="showUpdateKey">
        <div class="card-header bg-warning text-white">
          <i class="bwi bwi-exclamation-triangle bwi-fw" aria-hidden="true"></i>
          {{ 'updateKeyTitle' | i18n }}
        </div>
        <div class="card-body">
          <p>{{ 'updateEncryptionKeyShortDesc' | i18n }}</p>
          <button class="btn btn-block btn-outline-secondary" type="button" (click)="updateKey()">
            {{ 'updateEncryptionKey' | i18n }}
          </button>
        </div>
      </div>
      <app-verify-email *ngIf="showVerifyEmail" class="d-block"></app-verify-email>
      <div class="card border-warning mb-4" *ngIf="showBrowserOutdated">
        <div class="card-header bg-warning text-white">
          <i class="bwi bwi-exclamation-triangle bwi-fw" aria-hidden="true"></i>
          {{ 'updateBrowser' | i18n }}
        </div>
        <div class="card-body">
          <p>{{ 'updateBrowserDesc' | i18n }}</p>
          <a class="btn btn-block btn-outline-secondary" target="_blank" href="https://browser-update.org/update-browser.html" rel="noopener">
            {{ 'updateBrowser' | i18n }}
          </a>
        </div>
      </div>
      <div class="card border-success mb-4 -flat" *ngIf="showPremiumCallout">
        <div class="card-header bg-success text-white"><i class="bwi bwi-star-f bwi-fw" aria-hidden="true"></i> {{ 'goPremium' | i18n }}</div>
        <div class="card-body">
          <p>{{ 'premiumUpgradeUnlockFeatures' | i18n }}</p>
          <a class="btn btn-block btn-outline-secondary" routerLink="/settings/subscription/premium">
            {{ 'goPremium' | i18n }}
          </a>
        </div>
      </div>
      <div class="card -flat">
        <div class="card-header d-flex">
          {{ 'organizations' | i18n }}
          <a
            class="ml-auto a-help"
            tippy="Les organisations mettent en relation les utilisateurs de SUPASS pour un partage sécurisé des connexions, des notes, des cartes et des identités. "
            [href]="helpUrl + 'what-is-an-organization/'"
            target="_blank"
            rel="noopener"
            appA11yTitle="{{ 'learnMore' | i18n }}"
          >
            <i class="fa fa-question-circle-o" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="card card-body -flat">
        <a href="#" routerLink="/create-organization" class="btn btn-text">
          <i class="fa fa-plus fa-fw" aria-hidden="true"></i>
          &nbsp;{{ 'newOrganization' | i18n }}
        </a>
        <ul class="card-ul carets" *ngIf="organizationsForSorbonneUI && organizationsForSorbonneUI.length">
          <li *ngFor="let o of organizationsForSorbonneUI">
            <a [routerLink]="['/organizations', o.id]" class="text-body">
              <i class="fa fa-building" aria-hidden="true"></i> {{ o.name }}
              <ng-container *ngIf="!o.enabled">
                <i class="fa fa-exclamation-triangle text-danger" title="{{ 'organizationIsDisabled' | i18n }}" aria-hidden="true"></i>
                <span class="sr-only">{{ 'organizationIsDisabled' | i18n }}</span>
              </ng-container>
            </a>
            <ul *ngIf="organizationsCollectionsMap[o.id].length" class="card-ul carets">
              <ng-template #recursiveCollections let-collections>
                <li *ngFor="let c of collections" [ngClass]="{ active: c.node.id === selectedCollectionId }">
                  <a appStopClick (click)="selectCollection(c.node)">
                    <span class="position-relative"
                      >{{ c.node.name }}

                      <span *ngIf="isDupeCollection(c.node, collections)" class="ml-2 badge badge-light rounded-sm font-weight-normal text-uppercase">
                        <i class="fa fa-hashtag"></i> {{ c.node.id.slice(0, 8) }}
                      </span>
                    </span>
                  </a>
                  <ul class="card-ul carets" *ngIf="c.children.length">
                    <ng-container *ngTemplateOutlet="recursiveCollections; context: { $implicit: c.children }"></ng-container>
                  </ul>
                </li>
              </ng-template>
              <ng-container *ngTemplateOutlet="recursiveCollections; context: { $implicit: organizationsCollectionsMap[o.id] }"></ng-container>
            </ul>
          </li>
        </ul>
        <p *ngIf="!organizationsForSorbonneUI || !organizationsForSorbonneUI.length">{{ 'noOrganizationsList' | i18n }}</p>
        <!-- DEPRACATED !-->
        <!-- ROUTES for create organisation has changed and old components are not available any more !-->
        <!--        <div class="card-body">-->
        <!--          <app-organizationsForSorbonneUI [vault]="true" (onCollectionClicked)="filterCollection($event.id)"></app-organizationsForSorbonneUI>-->
        <!--        </div>-->
        <!-- END DEPRACATED !-->
      </div>
    </div>
  </div>
</div>
<ng-template #attachments></ng-template>
<ng-template #folderAddEdit></ng-template>
<ng-template #cipherAddEdit></ng-template>
<ng-template #share></ng-template>
<ng-template #collections></ng-template>
<ng-template #updateKeyTemplate></ng-template>
