<div class="container-fluid page-content">
  <div class="row page-row no-gutters">
    <div class="col-3 page-left">
      <div class="card -flat">
        <div class="card-header">{{ 'tools' | i18n }}</div>
        <div class="list-group list-group-flush">
          <a routerLink="generator" class="list-group-item" routerLinkActive="active">
            {{ 'generator' | i18n }}
          </a>
          <a routerLink="import" class="list-group-item" routerLinkActive="active">
            {{ 'importData' | i18n }}
          </a>
          <a routerLink="export" class="list-group-item" routerLinkActive="active">
            {{ 'exportVault' | i18n }}
          </a>
        </div>
      </div>
    </div>
    <div class="col-9 page-sub-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
