<!--<div class="filter-heading">-->
<!--  <button-->
<!--    class="toggle-button"-->
<!--    [attr.aria-expanded]="!isCollapsed"-->
<!--    aria-controls="type-filters"-->
<!--    (click)="toggleCollapse()"-->
<!--    title="{{ 'toggleCollapse' | i18n }}"-->
<!--  >-->
<!--    <i-->
<!--      class="bwi bwi-fw"-->
<!--      aria-hidden="true"-->
<!--      [ngClass]="{-->
<!--        'bwi-angle-right': isCollapsed,-->
<!--        'bwi-angle-down': !isCollapsed-->
<!--      }"-->
<!--    ></i>-->
<!--  </button>-->
<!--  <h3>&nbsp;{{ 'types' | i18n }}</h3>-->
<!--</div>-->
<h3 class="d-flex">
  {{ 'type' | i18n }}
</h3>
<ul id="type-filters" *ngIf="!isCollapsed" class="filter-options card-ul">
  <li class="filter-option" [ngClass]="{ active: activeFilter.cipherType === cipherTypeEnum.Login }">
    <a href="#" appStopClick class="filter-button" (click)="applyFilter(cipherTypeEnum.Login)">
      <i class="fa fa-fw fa-globe" aria-hidden="true"></i>&nbsp;{{ 'typeLogin' | i18n }}
    </a>
  </li>
  <!--  <li class="filter-option" [ngClass]="{ active: activeFilter.cipherType === cipherTypeEnum.Card }">-->
  <!--    <span class="filter-buttons">-->
  <!--      <button class="filter-button" (click)="applyFilter(cipherTypeEnum.Card)">-->
  <!--        <i class="fa fa-fw fa-id-card-o" aria-hidden="true"></i>&nbsp;{{ 'typeCard' | i18n }}-->
  <!--      </button>-->
  <!--    </span>-->
  <!--  </li>-->
  <li class="filter-option" [ngClass]="{ active: activeFilter.cipherType === cipherTypeEnum.Identity }">
    <a href="#" appStopClick class="filter-button" (click)="applyFilter(cipherTypeEnum.Identity)">
      <i class="fa fa-fw fa-id-card-o" aria-hidden="true"></i>&nbsp;{{ 'typeIdentity' | i18n }}
    </a>
  </li>
  <li class="filter-option" [ngClass]="{ active: activeFilter.cipherType === cipherTypeEnum.SecureNote }">
    <a href="#" appStopClick class="filter-button" (click)="applyFilter(cipherTypeEnum.SecureNote)">
      <i class="bwi bwi-fw bwi-sticky-note" aria-hidden="true"></i>&nbsp;{{ 'typeSecureNote' | i18n }}
    </a>
  </li>
</ul>
