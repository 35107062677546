<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="passHistoryTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="passHistoryTitle">{{ 'passwordHistory' | i18n }}</h2>
        <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{ 'close' | i18n }}">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="history.length">
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex" *ngFor="let h of history">
            <div class="password-row">
              <div class="text-monospace generated-wrapper" [innerHTML]="h.password | colorPassword" appSelectCopy></div>
              <small class="text-muted">{{ h.date | date: 'medium' }}</small>
            </div>
            <div class="ml-auto">
              <button class="btn btn-link" appA11yTitle="{{ 'copyPassword' | i18n }}" (click)="copy(h.password)">
                <i class="bwi bwi-lg bwi-clone" aria-hidden="true"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-body" *ngIf="!history.length">
        {{ 'noPasswordsInList' | i18n }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ 'close' | i18n }}
        </button>
        <div class="ml-auto">
          <button type="button" (click)="clear()" class="btn btn-outline-danger" appA11yTitle="{{ 'clear' | i18n }}">
            <i class="bwi bwi-trash bwi-lg bwi-fw" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
