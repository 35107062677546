<div class="tabbed-header">
  <h1>{{ 'encKeySettings' | i18n }}</h1>
</div>
<bit-callout type="warning">{{ 'loggedOutWarning' | i18n }}</bit-callout>
<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="kdfMasterPassword">{{ 'masterPass' | i18n }}</label>
        <input
          id="kdfMasterPassword"
          type="password"
          name="MasterPasswordHash"
          class="form-control"
          [(ngModel)]="masterPassword"
          required
          appInputVerbatim
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group mb-0">
        <label for="kdf">{{ 'kdfAlgorithm' | i18n }}</label>
        <a
          class="ml-auto"
          href="https://en.wikipedia.org/wiki/Key_derivation_function"
          target="_blank"
          rel="noopener"
          appA11yTitle="{{ 'learnMore' | i18n }}"
        >
          <i class="fa fa-question-circle-o" aria-hidden="true"></i>
        </a>
        <select id="kdf" name="Kdf" [(ngModel)]="kdf" class="form-control" required>
          <option *ngFor="let o of kdfOptions" [ngValue]="o.value">{{ o.name }}</option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group mb-0">
        <label for="kdfIterations">{{ 'kdfIterations' | i18n }}</label>
        <a
          class="ml-auto"
          href="https://bitwarden.com/help/what-encryption-is-used/#pbkdf2"
          target="_blank"
          rel="noopener"
          appA11yTitle="{{ 'learnMore' | i18n }}"
        >
          <i class="fa fa-question-circle-o" aria-hidden="true"></i>
        </a>
        <input
          id="kdfIterations"
          type="number"
          min="5000"
          max="2000000"
          name="KdfIterations"
          class="form-control"
          [(ngModel)]="kdfIterations"
          required
        />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <div class="small form-text text-muted">
          <p>{{ 'kdfIterationsDesc' | i18n: (recommendedKdfIterations | number) }}</p>
          <strong>{{ 'warning' | i18n }}</strong
          >: {{ 'kdfIterationsWarning' | i18n: (50000 | number) }}
        </div>
      </div>
    </div>
  </div>
  <button bitButton buttonType="primary" class="btn-submit" [disabled]="form.loading">
    <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
    <span>{{ 'changeKdf' | i18n }}</span>
  </button>
</form>
