<div class="dropdown mr-2" appListDropdown>
  <button
    class="btn dropdown-toggle"
    type="button"
    id="bulkActionsButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    appA11yTitle="{{ 'options' | i18n }}"
  >
    <i class="fa fa-cog" aria-hidden="true"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="bulkActionsButton">
    <button class="dropdown-item" appStopClick (click)="bulkMove()" *ngIf="!deleted && !organization">
      <i class="fa fa-fw fa-share" aria-hidden="true"></i>
      {{ 'moveSelected' | i18n }}
    </button>
    <button class="dropdown-item" appStopClick (click)="bulkShare()" *ngIf="!deleted && !organization">
      <i class="fa fa-fw fa-share-alt" aria-hidden="true"></i>
      {{ 'moveSelectedToOrg' | i18n }}
    </button>
    <button class="dropdown-item" (click)="bulkRestore()" *ngIf="deleted && !organization">
      <i class="bwi bwi-fw bwi-undo" aria-hidden="true"></i>
      {{ 'restoreSelected' | i18n }}
    </button>
    <button class="dropdown-item bg-danger" style="color: white" (click)="bulkDelete()">
      <i class="fa fa-fw fa-trash-o" aria-hidden="true"></i>
      {{ (deleted ? 'permanentlyDeleteSelected' : 'deleteSelected') | i18n }}
    </button>
    <div class="dropdown-divider"></div>
    <button class="dropdown-item" appStopClick (click)="selectAll(true)">
      <i class="fa fa-fw fa-check-square-o" aria-hidden="true"></i>
      {{ 'selectAll' | i18n }}
    </button>
    <button class="dropdown-item" appStopClick (click)="selectAll(false)">
      <i class="fa fa-fw fa-minus-square-o" aria-hidden="true"></i>
      {{ 'unselectAll' | i18n }}
    </button>
  </div>
</div>

<ng-template #bulkDeleteTemplate></ng-template>
<ng-template #bulkRestoreTemplate></ng-template>
<ng-template #bulkMoveTemplate></ng-template>
<ng-template #bulkShareTemplate></ng-template>
