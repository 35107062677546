import { NgModule } from '@angular/core'

import { CollectionService as CollectionServiceAbstraction } from 'jslib-common/abstractions/collection.service'
import { CryptoService as CryptoServiceAbstraction } from 'jslib-common/abstractions/crypto.service'
import { CryptoFunctionService as CryptoFunctionServiceAbstraction } from 'jslib-common/abstractions/cryptoFunction.service'
import { I18nService as I18nServiceAbstraction } from 'jslib-common/abstractions/i18n.service'
import { StateService as StateServiceAbstraction } from 'jslib-common/abstractions/state.service'
import { CollectionService } from 'jslib-common/services/collection.service'

import { TippyDirective } from '../../../../../../jslib/angular/src/directives/tippy.directive'
import { UserService } from '../../../../../../jslib/angular/src/services/user.service'
import { VaultModule } from '../../vault.module'

import { IndividualVaultRoutingModule } from './individual-vault-routing.module'
import { IndividualVaultComponent } from './individual-vault.component'
@NgModule({
  imports: [VaultModule, IndividualVaultRoutingModule],
  declarations: [IndividualVaultComponent, TippyDirective],
  exports: [IndividualVaultComponent, TippyDirective],
  providers: [
    {
      provide: UserService,
      useClass: UserService,
    },
    {
      provide: CollectionServiceAbstraction,
      useClass: CollectionService,
      deps: [CryptoServiceAbstraction, I18nServiceAbstraction, StateServiceAbstraction],
    },
  ],
})
export class IndividualVaultModule {}
