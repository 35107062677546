<app-callout type="warning">
  {{ 'twoStepLoginPolicyWarning' | i18n }}
</app-callout>

<div class="form-group">
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="enabled" [formControl]="enabled" name="Enabled" />
    <label class="form-check-label" for="enabled">{{ 'enabled' | i18n }}</label>
  </div>
</div>
