<ng-container *ngIf="show">
  <h3 class="d-flex">&nbsp;{{ collectionsGrouping.name | i18n }}</h3>

  <ul id="collection-filters" class="card-ul carets" *ngIf="!isCollapsed(collectionsGrouping)">
    <ng-template #recursiveCollections let-collections>
      <li
        *ngFor="let c of collections"
        [ngClass]="{
          active: c.node.id === activeFilter.selectedCollectionId
        }"
        class="filter-option"
      >
        <button
          class="toggle-button"
          *ngIf="c.children.length"
          (click)="toggleCollapse(c.node)"
          title="{{ 'toggleCollapse' | i18n }}"
          [attr.aria-expanded]="!isCollapsed(c.node)"
          [attr.aria-controls]="c.node.name + '_children'"
        >
          <i
            class="bwi bwi-fw"
            [ngClass]="{
              'bwi-angle-right': isCollapsed(c.node),
              'bwi-angle-down': !isCollapsed(c.node)
            }"
            aria-hidden="true"
          ></i>
        </button>
        <a appstopclick href="#" (click)="applyFilter(c.node)">
          <i class="fa fa-caret-down" *ngIf="c.children.length === 0" aria-hidden="true"></i>&nbsp;
          <span class="position-relative">
            {{ c.node.name }}
          </span>
        </a>
        <ul [id]="c.node.name + '_children'" class="nested-filter-options" *ngIf="c.children.length && !isCollapsed(c.node)">
          <ng-container *ngTemplateOutlet="recursiveCollections; context: { $implicit: c.children }"></ng-container>
        </ul>
      </li>
    </ng-template>
    <ng-container *ngTemplateOutlet="recursiveCollections; context: { $implicit: nestedCollections }"></ng-container>
  </ul>
</ng-container>
