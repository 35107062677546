<div class="container-fluid page-content">
  <div class="row page-row no-gutters">
    <div class="col-3 page-left">
      <div class="card -flat" *ngIf="organization" style="background-color: transparent">
        <div class="card-header" style="background-color: transparent">{{ 'manage' | i18n }}</div>
        <div class="list-group list-group-flush" style="background-color: transparent">
          <a routerLink="people" class="list-group-item" routerLinkActive="active" *ngIf="organization.canManageUsers">
            {{ 'people' | i18n }}
          </a>
          <a
            routerLink="collections"
            class="list-group-item"
            routerLinkActive="active"
            *ngIf="organization.canViewAllCollections || organization.canViewAssignedCollections"
          >
            {{ 'collections' | i18n }}
          </a>
          <a routerLink="groups" class="list-group-item" routerLinkActive="active" *ngIf="organization.canManageGroups && accessGroups">
            {{ 'groups' | i18n }}
          </a>
          <a routerLink="policies" class="list-group-item" routerLinkActive="active" *ngIf="organization.canManagePolicies && accessPolicies">
            {{ 'policies' | i18n }}
          </a>
          <a routerLink="sso" class="list-group-item" routerLinkActive="active" *ngIf="organization.canManageSso && accessSso">
            {{ 'singleSignOn' | i18n }}
          </a>
          <a routerLink="events" class="list-group-item" routerLinkActive="active" *ngIf="organization.canAccessEventLogs && accessEvents">
            {{ 'eventLogs' | i18n }}
          </a>
        </div>
      </div>
    </div>
    <div class="col-9 page-sub-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
