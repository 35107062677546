<div class="container-fluid page-content">
  <div class="row page-row no-gutters">
    <div class="col-3 page-left">
      <div class="card -flat" style="background-color: transparent">
        <div class="card-header" style="background-color: transparent">{{ 'settings' | i18n }}</div>
        <div class="list-group list-group-flush">
          <a routerLink="account" class="list-group-item" routerLinkActive="active">
            {{ 'myOrganization' | i18n }}
          </a>
          <!--          <a routerLink="subscription" class="list-group-item" routerLinkActive="active">-->
          <!--            {{ 'subscription' | i18n }}-->
          <!--          </a>-->
          <!--          <a routerLink="billing" class="list-group-item" routerLinkActive="active" *ngIf="showBilling">-->
          <!--            {{ 'billing' | i18n }}-->
          <!--          </a>-->
          <a routerLink="two-factor" class="list-group-item" routerLinkActive="active" *ngIf="access2fa">
            {{ 'twoStepLogin' | i18n }}
          </a>
        </div>
      </div>
    </div>
    <div class="col-9 page-sub-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
