<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
  <div class="form-group">
    <label for="file" class="sr-only">{{ 'licenseFile' | i18n }}</label>
    <input type="file" id="file" class="form-control-file" name="file" required />
    <small class="form-text text-muted">{{
      'licenseFileDesc' | i18n: (!organizationId ? 'bitwarden_premium_license.json' : 'bitwarden_organization_license.json')
    }}</small>
  </div>
  <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
    <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
    <span>{{ 'submit' | i18n }}</span>
  </button>
  <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
    {{ 'cancel' | i18n }}
  </button>
</form>
