import { Component, NgZone } from '@angular/core'
import { Router } from '@angular/router'

import { LockComponent as BaseLockComponent } from 'jslib-angular/components/lock.component'
import { ApiService } from 'jslib-common/abstractions/api.service'
import { CryptoService } from 'jslib-common/abstractions/crypto.service'
import { EnvironmentService } from 'jslib-common/abstractions/environment.service'
import { I18nService } from 'jslib-common/abstractions/i18n.service'
import { KeyConnectorService } from 'jslib-common/abstractions/keyConnector.service'
import { LogService } from 'jslib-common/abstractions/log.service'
import { MessagingService } from 'jslib-common/abstractions/messaging.service'
import { PlatformUtilsService } from 'jslib-common/abstractions/platformUtils.service'
import { StateService } from 'jslib-common/abstractions/state.service'
import { VaultTimeoutService } from 'jslib-common/abstractions/vaultTimeout.service'

import { RouterService } from '../services/router.service'

@Component({
  selector: 'app-lock',
  templateUrl: 'lock.component.html',
})
export class LockComponent extends BaseLockComponent {
  constructor(
    router: Router,
    i18nService: I18nService,
    platformUtilsService: PlatformUtilsService,
    messagingService: MessagingService,
    cryptoService: CryptoService,
    vaultTimeoutService: VaultTimeoutService,
    environmentService: EnvironmentService,
    private routerService: RouterService,
    stateService: StateService,
    apiService: ApiService,
    logService: LogService,
    keyConnectorService: KeyConnectorService,
    ngZone: NgZone
  ) {
    super(
      router,
      i18nService,
      platformUtilsService,
      messagingService,
      cryptoService,
      vaultTimeoutService,
      environmentService,
      stateService,
      apiService,
      logService,
      keyConnectorService,
      ngZone
    )
  }

  async ngOnInit() {
    await super.ngOnInit()
    this.onSuccessfulSubmit = async () => {
      const previousUrl = this.routerService.getPreviousUrl()
      if (previousUrl && previousUrl !== '/' && previousUrl.indexOf('lock') === -1) {
        this.successRoute = previousUrl
      }
      this.router.navigateByUrl(this.successRoute)
    }
  }
}
