import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { UpdatePasswordComponent as BaseUpdatePasswordComponent } from 'jslib-angular/components/update-password.component'
import { ApiService } from 'jslib-common/abstractions/api.service'
import { CryptoService } from 'jslib-common/abstractions/crypto.service'
import { I18nService } from 'jslib-common/abstractions/i18n.service'
import { LogService } from 'jslib-common/abstractions/log.service'
import { MessagingService } from 'jslib-common/abstractions/messaging.service'
import { PasswordGenerationService } from 'jslib-common/abstractions/passwordGeneration.service'
import { PlatformUtilsService } from 'jslib-common/abstractions/platformUtils.service'
import { PolicyService } from 'jslib-common/abstractions/policy.service'
import { StateService } from 'jslib-common/abstractions/state.service'
import { UserVerificationService } from 'jslib-common/abstractions/userVerification.service'

@Component({
  selector: 'app-update-password',
  templateUrl: 'update-password.component.html',
})
export class UpdatePasswordComponent extends BaseUpdatePasswordComponent {
  constructor(
    router: Router,
    i18nService: I18nService,
    platformUtilsService: PlatformUtilsService,
    passwordGenerationService: PasswordGenerationService,
    policyService: PolicyService,
    cryptoService: CryptoService,
    messagingService: MessagingService,
    apiService: ApiService,
    logService: LogService,
    stateService: StateService,
    userVerificationService: UserVerificationService
  ) {
    super(
      router,
      i18nService,
      platformUtilsService,
      passwordGenerationService,
      policyService,
      cryptoService,
      messagingService,
      apiService,
      stateService,
      userVerificationService,
      logService
    )
  }
}
