<div class="tabbed-header d-flex">
  <h1>
    {{ 'billingHistory' | i18n }}
  </h1>
  <button bitButton buttonType="secondary" (click)="load()" class="tw-ml-auto" *ngIf="firstLoaded" [disabled]="loading">
    <i class="bwi bwi-refresh bwi-fw" [ngClass]="{ 'bwi-spin': loading }" aria-hidden="true"></i>
    {{ 'refresh' | i18n }}
  </button>
</div>
<ng-container *ngIf="!firstLoaded && loading">
  <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
  <span class="sr-only">{{ 'loading' | i18n }}</span>
</ng-container>
<ng-container *ngIf="billing">
  <h2>{{ 'invoices' | i18n }}</h2>
  <p *ngIf="!invoices || !invoices.length">{{ 'noInvoices' | i18n }}</p>
  <table class="table mb-2" *ngIf="invoices && invoices.length">
    <tbody>
      <tr *ngFor="let i of invoices">
        <td>{{ i.date | date: 'mediumDate' }}</td>
        <td>
          <a href="{{ i.pdfUrl }}" target="_blank" rel="noopener" class="mr-2" appA11yTitle="{{ 'downloadInvoice' | i18n }}">
            <i class="bwi bwi-file-pdf" aria-hidden="true"></i
          ></a>
          <a href="{{ i.url }}" target="_blank" rel="noopener" title="{{ 'viewInvoice' | i18n }}"> {{ 'invoiceNumber' | i18n: i.number }}</a>
        </td>
        <td>{{ i.amount | currency: '$' }}</td>
        <td>
          <span *ngIf="i.paid">
            <i class="bwi bwi-check text-success" aria-hidden="true"></i>
            {{ 'paid' | i18n }}
          </span>
          <span *ngIf="!i.paid">
            <i class="bwi bwi-exclamation-circle text-muted" aria-hidden="true"></i>
            {{ 'unpaid' | i18n }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <h2 class="spaced-header">{{ 'transactions' | i18n }}</h2>
  <p *ngIf="!transactions || !transactions.length">{{ 'noTransactions' | i18n }}</p>
  <table class="table mb-2" *ngIf="transactions && transactions.length">
    <tbody>
      <tr *ngFor="let t of transactions">
        <td>{{ t.createdDate | date: 'mediumDate' }}</td>
        <td>
          <span *ngIf="t.type === transactionType.Charge || t.type === transactionType.Credit">
            {{ 'chargeNoun' | i18n }}
          </span>
          <span *ngIf="t.type === transactionType.Refund">{{ 'refundNoun' | i18n }}</span>
        </td>
        <td>
          <i
            class="bwi bwi-fw"
            *ngIf="t.paymentMethodType"
            aria-hidden="true"
            [ngClass]="{
              'bwi-credit-card': t.paymentMethodType === paymentMethodType.Card,
              'bwi-bank': t.paymentMethodType === paymentMethodType.BankAccount || t.paymentMethodType === paymentMethodType.WireTransfer,
              'bwi-bitcoin text-warning': t.paymentMethodType === paymentMethodType.BitPay,
              'bwi-paypal text-primary': t.paymentMethodType === paymentMethodType.PayPal
            }"
          ></i>
          {{ t.details }}
        </td>
        <td [ngClass]="{ 'text-strike': t.refunded }" title="{{ (t.refunded ? 'refunded' : '') | i18n }}">
          {{ t.amount | currency: '$' }}
        </td>
      </tr>
    </tbody>
  </table>
  <small class="text-muted">* {{ 'chargesStatement' | i18n: 'BITWARDEN' }}</small>
</ng-container>
