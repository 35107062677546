<div class="page-header">
  <h1>
    {{ 'subscription' | i18n }}
    <small *ngIf="firstLoaded && loading">
      <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span class="sr-only">{{ 'loading' | i18n }}</span>
    </small>
  </h1>
</div>
<ng-container *ngIf="!firstLoaded && loading">
  <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}"></i>
  <span class="sr-only">{{ 'loading' | i18n }}</span>
</ng-container>

<ng-container *ngIf="firstLoaded && !userOrg.canManageBilling">
  <div class="tw-flex tw-flex-col tw-items-center tw-text-info">
    <app-image-org-subscription-hidden></app-image-org-subscription-hidden>
    <p class="tw-font-bold">{{ 'billingManagedByProvider' | i18n: this.userOrg.providerName }}</p>
    <p>{{ 'billingContactProviderForAssistance' | i18n }}</p>
  </div>
</ng-container>

<ng-container *ngIf="sub">
  <app-callout type="warning" title="{{ 'canceled' | i18n }}" *ngIf="subscription && subscription.cancelled">
    {{ 'subscriptionCanceled' | i18n }}</app-callout
  >
  <app-callout type="warning" title="{{ 'pendingCancellation' | i18n }}" *ngIf="subscriptionMarkedForCancel">
    <p>{{ 'subscriptionPendingCanceled' | i18n }}</p>
    <button
      #reinstateBtn
      type="button"
      class="btn btn-outline-secondary btn-submit"
      (click)="reinstate()"
      [appApiAction]="reinstatePromise"
      [disabled]="reinstateBtn.loading"
    >
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ 'reinstateSubscription' | i18n }}</span>
    </button>
  </app-callout>
  <ng-container *ngIf="!selfHosted">
    <div class="row">
      <div class="col-4">
        <dl>
          <dt>{{ 'billingPlan' | i18n }}</dt>
          <dd>{{ sub.plan.name }}</dd>
          <ng-container *ngIf="subscription">
            <dt>{{ 'status' | i18n }}</dt>
            <dd>
              <span class="text-capitalize">{{ isSponsoredSubscription ? 'sponsored' : subscription.status || '-' }}</span>
              <span class="badge badge-warning" *ngIf="subscriptionMarkedForCancel">{{ 'pendingCancellation' | i18n }}</span>
            </dd>
            <dt>{{ 'nextCharge' | i18n }}</dt>
            <dd>
              {{ nextInvoice ? (nextInvoice.date | date: 'mediumDate') + ', ' + (nextInvoice.amount | currency: '$') : '-' }}
            </dd>
          </ng-container>
        </dl>
      </div>
      <div class="col-8" *ngIf="subscription">
        <strong class="d-block mb-1">{{ 'details' | i18n }}</strong>
        <table class="table">
          <tbody>
            <tr *ngFor="let i of subscription.items">
              <td>
                {{ i.name }} {{ i.quantity > 1 ? '&times;' + i.quantity : '' }} @
                {{ i.amount | currency: '$' }}
              </td>
              <td>{{ i.quantity * i.amount | currency: '$' }} /{{ i.interval | i18n }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ng-container *ngIf="userOrg?.providerId != null">
        <div class="col-sm">
          <dl>
            <dt>{{ 'provider' | i18n }}</dt>
            <dd>{{ 'yourProviderIs' | i18n: userOrg.providerName }}</dd>
          </dl>
        </div>
      </ng-container>
    </div>
    <ng-container>
      <button type="button" class="btn btn-outline-secondary" (click)="changePlan()" *ngIf="showChangePlanButton">
        {{ 'changeBillingPlan' | i18n }}
      </button>
      <app-change-plan
        [organizationId]="organizationId"
        (onChanged)="closeChangePlan(true)"
        (onCanceled)="closeChangePlan(false)"
        *ngIf="showChangePlan"
      ></app-change-plan>
    </ng-container>
    <h2 class="spaced-header">{{ 'manageSubscription' | i18n }}</h2>
    <p class="mb-4">{{ subscriptionDesc }}</p>
    <ng-container *ngIf="subscription && canAdjustSeats && !subscription.cancelled && !subscriptionMarkedForCancel">
      <div class="mt-3">
        <app-adjust-subscription
          [seatPrice]="seatPrice"
          [organizationId]="organizationId"
          [interval]="billingInterval"
          [currentSeatCount]="seats"
          [maxAutoscaleSeats]="maxAutoscaleSeats"
          (onAdjusted)="subscriptionAdjusted()"
        >
        </app-adjust-subscription>
      </div>
    </ng-container>
    <button
      #removeSponsorshipBtn
      type="button"
      class="btn btn-outline-danger btn-submit"
      (click)="removeSponsorship()"
      [appApiAction]="removeSponsorshipPromise"
      [disabled]="removeSponsorshipBtn.loading"
      *ngIf="isSponsoredSubscription"
    >
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ 'removeSponsorship' | i18n }}</span>
    </button>
    <h2 class="spaced-header">{{ 'storage' | i18n }}</h2>
    <p>{{ 'subscriptionStorage' | i18n: sub.maxStorageGb || 0:sub.storageName || '0 MB' }}</p>
    <div class="progress">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        [ngStyle]="{ width: storageProgressWidth + '%' }"
        [attr.aria-valuenow]="storagePercentage"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ storagePercentage / 100 | percent }}
      </div>
    </div>
    <ng-container *ngIf="subscription && !subscription.cancelled && !subscriptionMarkedForCancel">
      <div class="mt-3">
        <div class="d-flex" *ngIf="!showAdjustStorage">
          <button type="button" class="btn btn-outline-secondary" (click)="adjustStorage(true)">
            {{ 'addStorage' | i18n }}
          </button>
          <button type="button" class="btn btn-outline-secondary ml-1" (click)="adjustStorage(false)">
            {{ 'removeStorage' | i18n }}
          </button>
        </div>
        <app-adjust-storage
          [storageGbPrice]="storageGbPrice"
          [add]="adjustStorageAdd"
          [organizationId]="organizationId"
          [interval]="billingInterval"
          (onAdjusted)="closeStorage(true)"
          (onCanceled)="closeStorage(false)"
          *ngIf="showAdjustStorage"
        ></app-adjust-storage>
      </div>
    </ng-container>
    <!--Switch to i18n-->
    <h2 class="spaced-header">{{ 'selfHostingTitle' | i18n }}</h2>
    <p class="mb-4">
      {{ 'selfHostingEnterpriseOrganizationSectionCopy' | i18n }}
    </p>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" (click)="downloadLicense()" *ngIf="canDownloadLicense" [disabled]="showDownloadLicense">
        {{ 'downloadLicense' | i18n }}
      </button>
      <button type="button" class="btn btn-outline-secondary ml-1" (click)="manageBillingSync()" *ngIf="canManageBillingSync">
        {{ (hasBillingSyncToken ? 'manageBillingSync' : 'setUpBillingSync') | i18n }}
      </button>
    </div>
    <div class="mt-3" *ngIf="showDownloadLicense">
      <app-download-license
        [organizationId]="organizationId"
        (onDownloaded)="closeDownloadLicense()"
        (onCanceled)="closeDownloadLicense()"
      ></app-download-license>
    </div>
    <h2 class="spaced-header">{{ 'additionalOptions' | i18n }}</h2>
    <p class="mb-4">
      {{ 'additionalOptionsDesc' | i18n }}
    </p>
    <div class="d-flex">
      <button
        #cancelBtn
        type="button"
        class="btn btn-outline-danger btn-submit ml-1"
        (click)="cancel()"
        [appApiAction]="cancelPromise"
        [disabled]="cancelBtn.loading"
        *ngIf="subscription && !subscription.cancelled && !subscriptionMarkedForCancel"
      >
        <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
        <span>{{ 'cancelSubscription' | i18n }}</span>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="selfHosted">
    <dl>
      <dt>{{ 'billingPlan' | i18n }}</dt>
      <dd>{{ sub.plan.name }}</dd>
      <dt>{{ 'expiration' | i18n }}</dt>
      <dd *ngIf="sub.expiration">
        {{ sub.expiration | date: 'mediumDate' }}
        <span *ngIf="isExpired" class="text-danger ml-2">
          <i class="bwi bwi-exclamation-triangle" aria-hidden="true"></i>
          {{ 'licenseIsExpired' | i18n }}
        </span>
      </dd>
      <dd *ngIf="!sub.expiration">{{ 'neverExpires' | i18n }}</dd>
    </dl>
    <div>
      <button type="button" class="btn btn-outline-secondary" (click)="updateLicense()">
        {{ 'updateLicense' | i18n }}
      </button>
      <a href="https://vault.bitwarden.com" target="_blank" rel="noopener" class="btn btn-outline-secondary">
        {{ 'manageSubscription' | i18n }}
      </a>
    </div>
    <div class="card mt-3" *ngIf="showUpdateLicense">
      <div class="card-body">
        <button type="button" class="close" appA11yTitle="{{ 'cancel' | i18n }}" (click)="closeUpdateLicense(false)">
          <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="card-body-header">{{ 'updateLicense' | i18n }}</h3>
        <app-update-license
          [organizationId]="organizationId"
          (onUpdated)="closeUpdateLicense(true)"
          (onCanceled)="closeUpdateLicense(false)"
        ></app-update-license>
      </div>
    </div>
    <div *ngIf="showBillingSyncKey">
      <h2 class="mt-5">
        {{ 'billingSync' | i18n }}
      </h2>
      <p>
        {{ 'billingSyncDesc' | i18n }}
      </p>
      <button type="button" class="btn btn-outline-secondary" (click)="manageBillingSyncSelfHosted()">
        {{ 'manageBillingSync' | i18n }}
      </button>
      <small class="form-text text-muted" *ngIf="billingSyncSetUp">
        {{ 'lastSync' | i18n }}:
        <span *ngIf="userOrg.familySponsorshipLastSyncDate != null">
          {{ userOrg.familySponsorshipLastSyncDate | date: 'medium' }}
        </span>
        <span *ngIf="userOrg.familySponsorshipLastSyncDate == null">
          {{ 'never' | i18n | lowercase }}
        </span>
      </small>
    </div>
  </ng-container>
</ng-container>
<ng-template #setupBillingSyncTemplate></ng-template>
<ng-template #rotateBillingSyncKeyTemplate></ng-template>
