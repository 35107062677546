<div
  [ngClass]="{
    'page-header': selfHosted,
    'tabbed-header': !selfHosted
  }"
>
  <h1>
    {{ title }}
    <small *ngIf="firstLoaded && loading">
      <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span class="sr-only">{{ 'loading' | i18n }}</span>
    </small>
  </h1>
</div>
<ng-container *ngIf="!firstLoaded && loading">
  <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
  <span class="sr-only">{{ 'loading' | i18n }}</span>
</ng-container>
<ng-container *ngIf="sub">
  <bit-callout type="warning" title="{{ 'canceled' | i18n }}" *ngIf="subscription && subscription.cancelled">
    {{ 'subscriptionCanceled' | i18n }}</bit-callout
  >
  <bit-callout type="warning" title="{{ 'pendingCancellation' | i18n }}" *ngIf="subscriptionMarkedForCancel">
    <p>{{ 'subscriptionPendingCanceled' | i18n }}</p>
    <button
      bitButton
      type="button"
      buttonType="secondary"
      #reinstateBtn
      class="btn-submit"
      (click)="reinstate()"
      [appApiAction]="reinstatePromise"
      [disabled]="reinstateBtn.loading"
    >
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ 'reinstateSubscription' | i18n }}</span>
    </button>
  </bit-callout>
  <dl *ngIf="selfHosted">
    <dt>{{ 'expiration' | i18n }}</dt>
    <dd *ngIf="sub.expiration">{{ sub.expiration | date: 'mediumDate' }}</dd>
    <dd *ngIf="!sub.expiration">{{ 'neverExpires' | i18n }}</dd>
  </dl>
  <div class="row" *ngIf="!selfHosted">
    <div class="col-4">
      <dl>
        <dt>{{ 'status' | i18n }}</dt>
        <dd>
          <span class="text-capitalize">{{ (subscription && subscription.status) || '-' }}</span>
          <span bitBadge badgeType="warning" *ngIf="subscriptionMarkedForCancel">{{ 'pendingCancellation' | i18n }}</span>
        </dd>
        <dt>{{ 'nextCharge' | i18n }}</dt>
        <dd>
          {{ nextInvoice ? (nextInvoice.date | date: 'mediumDate') + ', ' + (nextInvoice.amount | currency: '$') : '-' }}
        </dd>
      </dl>
    </div>
    <div class="col-8" *ngIf="subscription">
      <strong class="d-block mb-1">{{ 'details' | i18n }}</strong>
      <table class="table">
        <tbody>
          <tr *ngFor="let i of subscription.items">
            <td>
              {{ i.name }} {{ i.quantity > 1 ? '&times;' + i.quantity : '' }} @
              {{ i.amount | currency: '$' }}
            </td>
            <td>{{ i.quantity * i.amount | currency: '$' }} /{{ i.interval | i18n }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ng-container *ngIf="selfHosted">
    <div>
      <button type="button" bitButton buttonType="secondary" (click)="updateLicense()">
        {{ 'updateLicense' | i18n }}
      </button>
      <a bitButton buttonType="secondary" href="https://vault.bitwarden.com/#/settings/subscription" target="_blank" rel="noopener">
        {{ 'manageSubscription' | i18n }}
      </a>
    </div>
    <div class="card mt-3" *ngIf="showUpdateLicense">
      <div class="card-body">
        <button type="button" class="close" appA11yTitle="{{ 'cancel' | i18n }}" (click)="closeUpdateLicense(false)">
          <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="card-body-header">{{ 'updateLicense' | i18n }}</h3>
        <app-update-license (onUpdated)="closeUpdateLicense(true)" (onCanceled)="closeUpdateLicense(false)"> </app-update-license>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!selfHosted">
    <div class="d-flex">
      <button bitButton type="button" buttonType="secondary" (click)="downloadLicense()" *ngIf="!subscription || !subscription.cancelled">
        {{ 'downloadLicense' | i18n }}
      </button>
      <button
        bitButton
        #cancelBtn
        type="button"
        buttonType="danger"
        class="btn-submit tw-ml-auto"
        (click)="cancel()"
        [appApiAction]="cancelPromise"
        [disabled]="cancelBtn.loading"
        *ngIf="subscription && !subscription.cancelled && !subscriptionMarkedForCancel"
      >
        <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
        <span>{{ 'cancelSubscription' | i18n }}</span>
      </button>
    </div>
    <h2 class="spaced-header">{{ 'storage' | i18n }}</h2>
    <p>{{ 'subscriptionStorage' | i18n: sub.maxStorageGb || 0:sub.storageName || '0 MB' }}</p>
    <div class="progress">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        [ngStyle]="{ width: storageProgressWidth + '%' }"
        [attr.aria-valuenow]="storagePercentage"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ storagePercentage / 100 | percent }}
      </div>
    </div>
    <ng-container *ngIf="subscription && !subscription.cancelled && !subscriptionMarkedForCancel">
      <div class="mt-3">
        <div class="d-flex" *ngIf="!showAdjustStorage">
          <button bitButton type="button" buttonType="secondary" (click)="adjustStorage(true)">
            {{ 'addStorage' | i18n }}
          </button>
          <button bitButton type="button" buttonType="secondary" class="tw-ml-1" (click)="adjustStorage(false)">
            {{ 'removeStorage' | i18n }}
          </button>
        </div>
        <app-adjust-storage
          [storageGbPrice]="4"
          [add]="adjustStorageAdd"
          (onAdjusted)="closeStorage(true)"
          (onCanceled)="closeStorage(false)"
          *ngIf="showAdjustStorage"
        ></app-adjust-storage>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
