<div [formGroup]="data">
  <div class="form-group">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="enabled" [formControl]="enabled" name="Enabled" />
      <label class="form-check-label" for="enabled">{{ 'enabled' | i18n }}</label>
    </div>
  </div>

  <div class="row">
    <div class="col-6 form-group mb-0">
      <label for="defaultType">{{ 'defaultType' | i18n }}</label>
      <select id="defaultType" name="defaultType" formControlName="defaultType" class="form-control">
        <option *ngFor="let o of defaultTypes" [ngValue]="o.value">{{ o.name }}</option>
      </select>
    </div>
  </div>
  <h3 class="mt-4">{{ 'password' | i18n }}</h3>
  <div class="row">
    <div class="col-6 form-group">
      <label for="minLength">{{ 'minLength' | i18n }}</label>
      <input id="minLength" class="form-control" type="number" name="minLength" min="5" max="128" formControlName="minLength" />
    </div>
  </div>
  <div class="row">
    <div class="col-6 form-group">
      <label for="minNumbers">{{ 'minNumbers' | i18n }}</label>
      <input id="minNumbers" class="form-control" type="number" name="minNumbers" min="0" max="9" formControlName="minNumbers" />
    </div>
    <div class="col-6 form-group">
      <label for="minSpecial">{{ 'minSpecial' | i18n }}</label>
      <input id="minSpecial" class="form-control" type="number" name="minSpecial" min="0" max="9" formControlName="minSpecial" />
    </div>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="useUpper" formControlName="useUpper" name="useUpper" />
    <label class="form-check-label" for="useUpper">A-Z</label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="useLower" name="useLower" formControlName="useLower" />
    <label class="form-check-label" for="useLower">a-z</label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="useNumbers" name="useNumbers" formControlName="useNumbers" />
    <label class="form-check-label" for="useNumbers">0-9</label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="useSpecial" name="useSpecial" formControlName="useSpecial" />
    <label class="form-check-label" for="useSpecial">!@#$%^&amp;*</label>
  </div>
  <h3 class="mt-4">{{ 'passphrase' | i18n }}</h3>
  <div class="row">
    <div class="col-6 form-group">
      <label for="minNumberWords">{{ 'minimumNumberOfWords' | i18n }}</label>
      <input id="minNumberWords" class="form-control" type="number" name="minNumberWords" min="3" max="20" formControlName="minNumberWords" />
    </div>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="capitalize" name="capitalize" formControlName="capitalize" />
    <label class="form-check-label" for="capitalize">{{ 'capitalize' | i18n }}</label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="includeNumber" name="includeNumber" formControlName="includeNumber" />
    <label class="form-check-label" for="includeNumber">{{ 'includeNumber' | i18n }}</label>
  </div>
</div>
