<div class="container-fluid page-content">
  <div class="row page-row no-gutters">
    <div class="col-3 page-left">
      <div class="card -flat">
        <div class="card-header">{{ 'settings' | i18n }}</div>
        <div class="list-group list-group-flush">
          <a routerLink="account" class="list-group-item" routerLinkActive="active">
            {{ 'myAccount' | i18n }}
          </a>
          <a routerLink="security" class="list-group-item" routerLinkActive="active">
            {{ 'security' | i18n }}
          </a>
          <a routerLink="preferences" class="list-group-item" routerLinkActive="active">
            {{ 'preferences' | i18n }}
          </a>
          <!--          <a routerLink="subscription" class="list-group-item" routerLinkActive="active" *ngIf="!hideSubscription">-->
          <!--            {{ 'subscription' | i18n }}-->
          <!--          </a>-->
          <a routerLink="domain-rules" class="list-group-item" routerLinkActive="active">
            {{ 'domainRules' | i18n }}
          </a>
          <a routerLink="emergency-access" class="list-group-item" routerLinkActive="active">
            {{ 'emergencyAccess' | i18n }}
          </a>
          <!--          <a routerLink="sponsored-families" class="list-group-item" routerLinkActive="active" *ngIf="hasFamilySponsorshipAvailable">-->
          <!--            {{ 'sponsoredFamilies' | i18n }}-->
          <!--          </a>-->
        </div>
      </div>
    </div>
    <div class="col-9 page-sub-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
