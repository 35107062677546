<div class="mb-4 text-lg" *ngIf="showOptions && showMethods">
  <div class="form-check form-check-inline mr-4">
    <input
      class="form-check-input"
      type="radio"
      name="Method"
      id="method-card"
      [value]="paymentMethodType.Card"
      [(ngModel)]="method"
      (change)="changeMethod()"
    />
    <label class="form-check-label" for="method-card"> <i class="bwi bwi-fw bwi-credit-card" aria-hidden="true"></i> {{ 'creditCard' | i18n }}</label>
  </div>
  <div class="form-check form-check-inline mr-4" *ngIf="!hideBank">
    <input
      class="form-check-input"
      type="radio"
      name="Method"
      id="method-bank"
      [value]="paymentMethodType.BankAccount"
      [(ngModel)]="method"
      (change)="changeMethod()"
    />
    <label class="form-check-label" for="method-bank"> <i class="bwi bwi-fw bwi-bank" aria-hidden="true"></i> {{ 'bankAccount' | i18n }}</label>
  </div>
  <div class="form-check form-check-inline" *ngIf="!hidePaypal">
    <input
      class="form-check-input"
      type="radio"
      name="Method"
      id="method-paypal"
      [value]="paymentMethodType.PayPal"
      [(ngModel)]="method"
      (change)="changeMethod()"
    />
    <label class="form-check-label" for="method-paypal"> <i class="bwi bwi-fw bwi-paypal" aria-hidden="true"></i> PayPal</label>
  </div>
  <div class="form-check form-check-inline" *ngIf="!hideCredit">
    <input
      class="form-check-input"
      type="radio"
      name="Method"
      id="method-credit"
      [value]="paymentMethodType.Credit"
      [(ngModel)]="method"
      (change)="changeMethod()"
    />
    <label class="form-check-label" for="method-credit"> <i class="bwi bwi-fw bwi-dollar" aria-hidden="true"></i> {{ 'accountCredit' | i18n }}</label>
  </div>
</div>
<ng-container *ngIf="showMethods && method === paymentMethodType.Card">
  <div class="row">
    <div class="form-group col-4">
      <label for="stripe-card-number-element">{{ 'number' | i18n }}</label>
      <div id="stripe-card-number-element" class="form-control stripe-form-control"></div>
    </div>
    <div class="form-group col-8 d-flex align-items-end">
      <img src="../../images/cards.png" alt="Visa, MasterCard, Discover, AmEx, JCB, Diners Club, UnionPay" width="323" height="32" />
    </div>
    <div class="form-group col-4">
      <label for="stripe-card-expiry-element">{{ 'expiration' | i18n }}</label>
      <div id="stripe-card-expiry-element" class="form-control stripe-form-control"></div>
    </div>
    <div class="form-group col-4">
      <div class="d-flex">
        <label for="stripe-card-cvc-element">
          {{ 'securityCode' | i18n }}
        </label>
        <a
          href="https://www.cvvnumber.com/cvv.html"
          tabindex="-1"
          target="_blank"
          rel="noopener noreferrer"
          class="ml-auto"
          appA11yTitle="{{ 'learnMore' | i18n }}"
        >
          <i class="fa fa-question-circle-o" aria-hidden="true"></i>
        </a>
      </div>
      <div id="stripe-card-cvc-element" class="form-control stripe-form-control"></div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showMethods && method === paymentMethodType.BankAccount">
  <app-callout type="warning" title="{{ 'verifyBankAccount' | i18n }}">
    {{ 'verifyBankAccountInitialDesc' | i18n }} {{ 'verifyBankAccountFailureWarning' | i18n }}
  </app-callout>
  <div class="row">
    <div class="form-group col-6">
      <label for="routing_number">{{ 'routingNumber' | i18n }}</label>
      <input id="routing_number" class="form-control" type="text" name="routing_number" [(ngModel)]="bank.routing_number" required appInputVerbatim />
    </div>
    <div class="form-group col-6">
      <label for="account_number">{{ 'accountNumber' | i18n }}</label>
      <input id="account_number" class="form-control" type="text" name="account_number" [(ngModel)]="bank.account_number" required appInputVerbatim />
    </div>
    <div class="form-group col-6">
      <label for="account_holder_name">{{ 'accountHolderName' | i18n }}</label>
      <input id="account_holder_name" class="form-control" type="text" name="account_holder_name" [(ngModel)]="bank.account_holder_name" required />
    </div>
    <div class="form-group col-6">
      <label for="account_holder_type">{{ 'bankAccountType' | i18n }}</label>
      <select id="account_holder_type" class="form-control" name="account_holder_type" [(ngModel)]="bank.account_holder_type" required>
        <option value="">-- {{ 'select' | i18n }} --</option>
        <option value="company">{{ 'bankAccountTypeCompany' | i18n }}</option>
        <option value="individual">{{ 'bankAccountTypeIndividual' | i18n }}</option>
      </select>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showMethods && method === paymentMethodType.PayPal">
  <div class="mb-3">
    <div id="bt-dropin-container" class="mb-1"></div>
    <small class="text-muted">{{ 'paypalClickSubmit' | i18n }}</small>
  </div>
</ng-container>
<ng-container *ngIf="showMethods && method === paymentMethodType.Credit">
  <app-callout type="note">
    {{ 'makeSureEnoughCredit' | i18n }}
  </app-callout>
</ng-container>
