<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="updateEncKeyTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
      <div class="modal-header">
        <h2 class="modal-title" id="updateEncKeyTitle">{{ 'updateEncryptionKey' | i18n }}</h2>
        <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{ 'close' | i18n }}">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{ 'updateEncryptionKeyShortDesc' | i18n }} {{ 'updateEncryptionKeyDesc' | i18n }}
          <a href="https://bitwarden.com/help/account-encryption-key/#rotate-your-encryption-key" target="_blank" rel="noopener">{{
            'learnMore' | i18n
          }}</a>
        </p>
        <app-callout type="warning">{{ 'updateEncryptionKeyWarning' | i18n }}</app-callout>
        <label for="masterPassword">{{ 'masterPass' | i18n }}</label>
        <input
          id="masterPassword"
          type="password"
          name="MasterPasswordHash"
          class="form-control"
          [(ngModel)]="masterPassword"
          required
          appAutofocus
          appInputVerbatim
        />
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ 'updateEncryptionKey' | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ 'close' | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
