<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
  <div class="row justify-content-md-center mt-5">
    <div class="col-4">
      <p class="lead text-center mb-4">{{ 'updateMasterPassword' | i18n }}</p>
      <div class="card d-block">
        <div class="card-body">
          <app-callout type="warning">{{ 'masterPasswordInvalidWarning' | i18n }} </app-callout>
          <app-callout type="info" [enforcedPolicyOptions]="enforcedPolicyOptions" *ngIf="enforcedPolicyOptions"></app-callout>

          <form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="currentMasterPassword">{{ 'currentMasterPass' | i18n }}</label>
                  <input
                    id="currentMasterPassword"
                    type="password"
                    name="MasterPasswordHash"
                    class="form-control"
                    [(ngModel)]="currentMasterPassword"
                    required
                    appInputVerbatim
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="newMasterPassword">{{ 'newMasterPass' | i18n }}</label>
                  <input
                    id="newMasterPassword"
                    type="password"
                    name="NewMasterPasswordHash"
                    class="form-control mb-1"
                    [(ngModel)]="masterPassword"
                    (input)="updatePasswordStrength()"
                    required
                    appInputVerbatim
                    autocomplete="new-password"
                  />
                  <app-password-strength [score]="masterPasswordScore" [showText]="true"></app-password-strength>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="masterPasswordRetype">{{ 'confirmNewMasterPass' | i18n }}</label>
                  <input
                    id="masterPasswordRetype"
                    type="password"
                    name="MasterPasswordRetype"
                    class="form-control"
                    [(ngModel)]="masterPasswordRetype"
                    required
                    appInputVerbatim
                    autocomplete="new-password"
                  />
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
              <i class="fa fa-spinner fa-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
              <span>{{ 'changeMasterPassword' | i18n }}</span>
            </button>
            <button (click)="cancel()" type="button" class="btn btn-outline-secondary">
              <span>{{ 'cancel' | i18n }}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</form>
