<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" class="container" ngNativeValidate autocomplete="off">
  <div class="row justify-content-md-center mt-5">
    <div
      class="col-5"
      [ngClass]="{
        'col-9': selectedProviderType === providerType.Duo || selectedProviderType === providerType.OrganizationDuo
      }"
    >
      <p class="lead text-center mb-4">{{ title }}</p>
      <div class="card d-block">
        <div class="card-body">
          <ng-container *ngIf="selectedProviderType === providerType.Email || selectedProviderType === providerType.Authenticator">
            <p *ngIf="selectedProviderType === providerType.Authenticator">
              {{ 'enterVerificationCodeApp' | i18n }}
            </p>
            <p *ngIf="selectedProviderType === providerType.Email">
              {{ 'enterVerificationCodeEmail' | i18n: twoFactorEmail }}
            </p>
            <div class="form-group">
              <label for="code" class="sr-only">{{ 'verificationCode' | i18n }}</label>
              <input
                id="code"
                type="text"
                name="Code"
                class="form-control"
                [(ngModel)]="token"
                required
                appAutofocus
                inputmode="tel"
                appInputVerbatim
              />
              <small class="form-text" *ngIf="selectedProviderType === providerType.Email">
                <a href="#" appStopClick (click)="sendEmail(true)" [appApiAction]="emailPromise" *ngIf="selectedProviderType === providerType.Email">
                  {{ 'sendVerificationCodeEmailAgain' | i18n }}
                </a>
              </small>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedProviderType === providerType.Yubikey">
            <p class="text-center">{{ 'insertYubiKey' | i18n }}</p>
            <picture>
              <source srcset="../../images/yubikey.avif" type="image/avif" />
              <source srcset="../../images/yubikey.webp" type="image/webp" />
              <img src="../../images/yubikey.jpg" class="rounded img-fluid mb-3" alt="" />
            </picture>
            <div class="form-group">
              <label for="code" class="sr-only">{{ 'verificationCode' | i18n }}</label>
              <input
                id="code"
                type="password"
                name="Code"
                class="form-control"
                [(ngModel)]="token"
                required
                appAutofocus
                appInputVerbatim
                autocomplete="new-password"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="selectedProviderType === providerType.WebAuthn">
            <div id="web-authn-frame" class="mb-3">
              <iframe id="webauthn_iframe" [allow]="webAuthnAllow"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedProviderType === providerType.Duo || selectedProviderType === providerType.OrganizationDuo">
            <div id="duo-frame" class="mb-3">
              <iframe id="duo_iframe"></iframe>
            </div>
          </ng-container>
          <i
            class="bwi bwi-spinner text-muted bwi-spin pull-right"
            title="{{ 'loading' | i18n }}"
            *ngIf="form.loading && selectedProviderType === providerType.WebAuthn"
            aria-hidden="true"
          ></i>
          <div class="form-check" *ngIf="selectedProviderType != null">
            <input id="remember" type="checkbox" name="Remember" class="form-check-input" [(ngModel)]="remember" />
            <label for="remember" class="form-check-label">{{ 'rememberMe' | i18n }}</label>
          </div>
          <ng-container *ngIf="selectedProviderType == null">
            <p>{{ 'noTwoStepProviders' | i18n }}</p>
            <p>{{ 'noTwoStepProviders2' | i18n }}</p>
          </ng-container>
          <hr />
          <div [hidden]="!showCaptcha()">
            <iframe id="hcaptcha_iframe" height="80"></iframe>
          </div>
          <div class="d-flex mb-3">
            <button
              type="submit"
              class="btn btn-primary btn-block btn-submit"
              [disabled]="form.loading"
              *ngIf="
                selectedProviderType != null &&
                selectedProviderType !== providerType.Duo &&
                selectedProviderType !== providerType.OrganizationDuo &&
                selectedProviderType !== providerType.WebAuthn
              "
            >
              <span> <i class="fa fa-sign-in mr-2" aria-hidden="true"></i> {{ 'continue' | i18n }} </span>
              <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
            </button>
            <a routerLink="/login" class="btn btn-outline-secondary btn-block ml-1 mt-0">
              {{ 'cancel' | i18n }}
            </a>
          </div>
          <div class="text-center">
            <a href="#" appStopClick (click)="anotherMethod()">{{ 'useAnotherTwoStepMethod' | i18n }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<ng-template #twoFactorOptions></ng-template>
