<div style="background-color: transparent">
  <div class="container loading-spinner" *ngIf="!isLoaded">
    <i class="bwi bwi-spinner bwi-spin bwi-3x" aria-hidden="true"></i>
  </div>
  <div *ngIf="isLoaded">
    <div class="card-header d-flex" style="background-color: transparent">
      {{ 'filters' | i18n }}
      <a class="ml-auto" href="https://bitwarden.com/help/searching-vault/" target="_blank" rel="noopener" appA11yTitle="{{ 'learnMore' | i18n }}">
        <i class="fa fa-question-circle-o" aria-hidden="true"></i>
      </a>
    </div>
    <div class="card-body" style="background-color: transparent">
      <div class="search-wrapper">
        <div class="d-flex p-relative">
          <input
            type="search"
            placeholder="{{ (searchPlaceholder | i18n) || ('searchVault' | i18n) }}"
            id="search"
            class="form-control"
            [(ngModel)]="searchText"
            (input)="searchTextChanged()"
            autocomplete="off"
            appAutofocus
          />
          <svg-icon *ngIf="!searchText" key="search-su" [ngClass]="{ hidden: !searchText }"></svg-icon>
          <!--          <svg-icon *ngIf="searchText" key="cross-su" [ngClass]="{ hidden: !!searchText }" (click)="searchText = ''"></svg-icon>-->
        </div>
      </div>
      <!--      <app-organization-filter-->
      <!--        [hide]="hideOrganizations"-->
      <!--        [activeFilter]="activeFilter"-->
      <!--        [collapsedFilterNodes]="collapsedFilterNodes"-->
      <!--        [organizations]="organizations"-->
      <!--        [activePersonalOwnershipPolicy]="activePersonalOwnershipPolicy"-->
      <!--        [activeSingleOrganizationPolicy]="activeSingleOrganizationPolicy"-->
      <!--        (onNodeCollapseStateChange)="toggleFilterNodeCollapseState($event)"-->
      <!--        (onFilterChange)="applyFilter($event)"-->
      <!--      ></app-organization-filter>-->
      <div class="filter">
        <app-status-filter
          [hideFavorites]="hideFavorites"
          [hideTrash]="hideTrash"
          [activeFilter]="activeFilter"
          (onFilterChange)="applyFilter($event)"
        ></app-status-filter>
      </div>

      <div class="card-ul-divider"></div>

      <div class="filter">
        <app-type-filter
          [activeFilter]="activeFilter"
          [collapsedFilterNodes]="collapsedFilterNodes"
          (onNodeCollapseStateChange)="toggleFilterNodeCollapseState($event)"
          (onFilterChange)="applyFilter($event)"
        ></app-type-filter>
      </div>

      <div *ngIf="!areFoldersHidden()" class="card-ul-divider"></div>

      <div class="filter">
        <app-folder-filter
          *ngIf="!areFoldersHidden()"
          [hide]="hideFolders"
          [activeFilter]="activeFilter"
          [collapsedFilterNodes]="collapsedFilterNodes"
          [folderNodes]="folders"
          (onNodeCollapseStateChange)="toggleFilterNodeCollapseState($event)"
          (onFilterChange)="applyFilter($event)"
          (onAddFolder)="addFolder()"
          (onEditFolder)="editFolder($event)"
        ></app-folder-filter>
      </div>

      <div class="card-ul-divider"></div>

      <div class="filter">
        <app-collection-filter
          [hide]="hideCollections"
          [activeFilter]="activeFilter"
          [collapsedFilterNodes]="collapsedFilterNodes"
          [collectionNodes]="collections"
          (onNodeCollapseStateChange)="toggleFilterNodeCollapseState($event)"
          (onFilterChange)="applyFilter($event)"
        ></app-collection-filter>
      </div>
    </div>
  </div>
</div>
