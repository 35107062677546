<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="billingSyncApiKeyTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
      <div class="modal-header">
        <h2 class="modal-title" id="billingSyncApiKeyTitle">
          {{ (hasBillingToken ? 'viewBillingSyncToken' : 'generateBillingSyncToken') | i18n }}
        </h2>
        <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{ 'close' | i18n }}">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-user-verification [(ngModel)]="masterPassword" ngDefaultControl name="secret" *ngIf="!clientSecret"> </app-user-verification>
        <ng-container *ngIf="clientSecret && showRotateScreen">
          <p>{{ 'rotateBillingSyncTokenTitle' | i18n }}</p>
          <app-callout type="warning">
            {{ 'rotateBillingSyncTokenWarning' | i18n }}
          </app-callout>
        </ng-container>

        <div *ngIf="clientSecret && !showRotateScreen">
          <p>{{ 'copyPasteBillingSync' | i18n }}</p>
          <label for="clientSecret">Billing Sync Key</label>
          <div class="input-group">
            <input id="clientSecret" class="form-control text-monospace" type="text" [(ngModel)]="clientSecret" name="clientSecret" disabled />
            <div class="input-group-append">
              <button type="button" class="btn btn-outline-secondary" (click)="copy()" [appA11yTitle]="'copy' | i18n">
                <i class="bwi bwi-lg bwi-clone" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="small text-muted mt-2" *ngIf="showLastSyncText">
            <b class="font-weight-semibold">{{ 'lastSync' | i18n }}:</b>
            {{ lastSyncDate | date: 'medium' }}
          </div>
          <div class="small text-danger mt-2" *ngIf="showAwaitingSyncText">
            <i class="bwi bwi-error"></i>
            {{ (daysBetween === 1 ? 'awaitingSyncSingular' : 'awaitingSyncPlural') | i18n: daysBetween }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading" *ngIf="!clientSecret || showRotateScreen">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" *ngIf="form.loading"></i>
          <span>
            {{ submitButtonText }}
          </span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" *ngIf="!showRotateScreen">
          {{ 'close' | i18n }}
        </button>
        <button type="button" class="btn btn-outline-secondary" *ngIf="showRotateScreen" (click)="cancelRotate()">
          {{ 'cancel' | i18n }}
        </button>
        <button type="button" class="btn btn-outline-secondary" *ngIf="clientSecret && !showRotateScreen" (click)="rotateToken()">
          {{ 'rotateToken' | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
