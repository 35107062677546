<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="sendAddEditTitle">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
      <div class="modal-header">
        <h2 class="modal-title" id="sendAddEditTitle">{{ title }}</h2>
        <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{ 'close' | i18n }}">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="send">
        <app-callout *ngIf="disableSend">
          <span>{{ 'sendDisabledWarning' | i18n }}</span>
        </app-callout>
        <app-callout *ngIf="!disableSend && disableHideEmail">
          <span>{{ 'sendOptionsPolicyInEffect' | i18n }}</span>
          <ul class="mb-0">
            <li>{{ 'sendDisableHideEmailInEffect' | i18n }}</li>
          </ul>
        </app-callout>
        <div class="row">
          <div class="col-6 form-group">
            <label for="name">{{ 'name' | i18n }}</label>
            <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="send.name" required [readOnly]="disableSend" />
            <small class="form-text text-muted">{{ 'sendNameDesc' | i18n }}</small>
          </div>
        </div>
        <div class="row" *ngIf="!editMode">
          <div class="col-6 form-group">
            <label>{{ 'whatTypeOfSend' | i18n }}</label>
            <div class="form-check" *ngFor="let o of typeOptions">
              <input
                class="form-check-input"
                type="radio"
                [(ngModel)]="send.type"
                name="Type_{{ o.value }}"
                id="type_{{ o.value }}"
                [value]="o.value"
                (change)="typeChanged(o)"
                [checked]="send.type === o.value"
              />
              <label class="form-check-label" for="type_{{ o.value }}">
                {{ o.name }}
              </label>
            </div>
          </div>
        </div>
        <!-- Text -->
        <ng-container *ngIf="send.type === sendType.Text">
          <div class="form-group">
            <label for="text">{{ 'sendTypeText' | i18n }}</label>
            <textarea id="text" name="Text.Text" rows="6" [(ngModel)]="send.text.text" class="form-control" [readOnly]="disableSend"></textarea>
            <small class="form-text text-muted">{{ 'sendTextDesc' | i18n }}</small>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="send.text.hidden"
                id="text-hidden"
                name="Text.Hidden"
                [disabled]="disableSend"
              />
              <label class="form-check-label" for="text-hidden">{{ 'textHiddenByDefault' | i18n }}</label>
            </div>
          </div>
        </ng-container>
        <!-- File -->
        <ng-container *ngIf="send.type === sendType.File">
          <div class="form-group">
            <div *ngIf="editMode">
              <strong class="d-block">{{ 'file' | i18n }}</strong>
              {{ send.file.fileName }} ({{ send.file.sizeName }})
            </div>
            <div *ngIf="!editMode">
              <label for="file">{{ 'file' | i18n }}</label>
              <input type="file" id="file" class="form-control-file" name="file" required [disabled]="disableSend" />
              <small class="form-text text-muted">{{ 'sendFileDesc' | i18n }} {{ 'maxFileSize' | i18n }}</small>
            </div>
          </div>
        </ng-container>
        <h3 class="mt-5">{{ 'share' | i18n }}</h3>
        <div class="form-group" *ngIf="link">
          <label for="link">{{ 'sendLinkLabel' | i18n }}</label>
          <input type="text" readonly id="link" name="Link" [(ngModel)]="link" class="form-control" />
        </div>
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="copyLink" id="copy-link" name="CopyLink" />
            <label class="form-check-label" for="copy-link">{{ 'copySendLinkOnSave' | i18n }}</label>
          </div>
        </div>
        <div id="options-header" class="section-header d-flex flex-row align-items-center mt-5" (click)="toggleOptions()">
          <h3 class="mb-0 mr-2">{{ 'options' | i18n }}</h3>
          <a class="mb-1" href="#" appStopClick role="button">
            <i class="bwi" aria-hidden="true" [ngClass]="{ 'bwi-angle-down': !showOptions, 'bwi-chevron-up': showOptions }"></i>
          </a>
        </div>
        <div id="options" [hidden]="!showOptions">
          <app-send-efflux-dates
            [initialDeletionDate]="send.deletionDate"
            [initialExpirationDate]="send.expirationDate"
            [editMode]="editMode"
            [disabled]="disableSend"
            (datesChanged)="setDates($event)"
          >
          </app-send-efflux-dates>
          <div class="row">
            <div class="col-6 form-group">
              <label for="maxAccessCount">{{ 'maxAccessCount' | i18n }}</label>
              <input
                id="maxAccessCount"
                class="form-control"
                type="number"
                name="MaxAccessCount"
                [(ngModel)]="send.maxAccessCount"
                min="1"
                [readOnly]="disableSend"
              />
              <div class="form-text text-muted small">{{ 'maxAccessCountDesc' | i18n }}</div>
            </div>
            <div class="col-6 form-group" *ngIf="editMode">
              <label for="accessCount">{{ 'currentAccessCount' | i18n }}</label>
              <input id="accessCount" class="form-control" type="text" name="AccessCount" readonly [(ngModel)]="send.accessCount" />
            </div>
          </div>
          <div class="row">
            <div class="col-6 form-group">
              <label for="password" *ngIf="!hasPassword">{{ 'password' | i18n }}</label>
              <label for="password" *ngIf="hasPassword">{{ 'newPassword' | i18n }}</label>
              <div class="input-group">
                <input
                  id="password"
                  class="form-control text-monospace"
                  type="{{ showPassword ? 'text' : 'password' }}"
                  name="Password"
                  [(ngModel)]="password"
                  [readOnly]="disableSend"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    appA11yTitle="{{ 'toggleVisibility' | i18n }}"
                    (click)="togglePasswordVisible()"
                  >
                    <i class="bwi bwi-lg" aria-hidden="true" [ngClass]="{ 'bwi-eye': !showPassword, 'bwi-eye-slash': showPassword }"></i>
                  </button>
                </div>
              </div>
              <div class="form-text text-muted small">{{ 'sendPasswordDesc' | i18n }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="notes">{{ 'notes' | i18n }}</label>
            <textarea id="notes" name="Notes" rows="6" [(ngModel)]="send.notes" class="form-control" [readOnly]="disableSend"></textarea>
            <div class="form-text text-muted small">{{ 'sendNotesDesc' | i18n }}</div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="send.hideEmail"
                id="hideEmail"
                name="HideEmail"
                [disabled]="(disableHideEmail && !send.hideEmail) || disableSend"
              />
              <label class="form-check-label" for="hideEmail">
                {{ 'hideEmail' | i18n }}
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="send.disabled" id="disabled" name="Disabled" [disabled]="disableSend" />
              <label class="form-check-label" for="disabled">{{ 'disableThisSend' | i18n }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-primary btn-submit manual"
          [ngClass]="{ loading: form.loading }"
          [disabled]="form.loading || disableSend"
        >
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ 'save' | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ 'cancel' | i18n }}
        </button>
        <div class="ml-auto" *ngIf="send">
          <button
            #deleteBtn
            type="button"
            (click)="delete()"
            class="btn btn-outline-danger"
            appA11yTitle="{{ 'delete' | i18n }}"
            *ngIf="editMode"
            [disabled]="deleteBtn.loading"
            [appApiAction]="deletePromise"
          >
            <i class="bwi bwi-trash bwi-lg bwi-fw" [hidden]="deleteBtn.loading" aria-hidden="true"></i>
            <i class="bwi bwi-spinner bwi-spin bwi-lg bwi-fw" [hidden]="!deleteBtn.loading" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
