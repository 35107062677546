import {Component, EventEmitter, Output} from '@angular/core'
import {Router} from "@angular/router";

import {
  VaultFilterComponent as BaseVaultFilterComponent
} from 'jslib-angular/modules/vault-filter/vault-filter.component'

import {VaultFilterService} from './vault-filter.service'

@Component({
  selector: 'app-vault-filter',
  templateUrl: 'vault-filter.component.html',
})
export class VaultFilterComponent extends BaseVaultFilterComponent {
  @Output() onSearchTextChanged = new EventEmitter<string>()

  searchPlaceholder: string
  searchText = ''

  constructor(
    protected vaultFilterService: VaultFilterService,
    private router: Router
  ) {
    // This empty constructor is required to provide the web vaultFilterService subclass to super()
    // TODO: refactor this to use proper dependency injection
    super(vaultFilterService)
  }

  areFoldersHidden(): boolean {
    return this.router.url.indexOf('/organizations') !== -1;
  }

  searchTextChanged() {
    this.onSearchTextChanged.emit(this.searchText)
  }
}
