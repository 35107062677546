<div class="card border-warning">
  <div class="card-header bg-warning text-white"><i class="bwi bwi-envelope bwi-fw" aria-hidden="true"></i> {{ 'verifyEmail' | i18n }}</div>
  <div class="card-body">
    <p>{{ 'verifyEmailDesc' | i18n }}</p>
    <button
      type="button"
      class="btn btn-block btn-outline-secondary btn-submit"
      #sendBtn
      [appApiAction]="actionPromise"
      [disabled]="sendBtn.loading"
      (click)="send()"
    >
      <i class="bwi bwi-spin bwi-spinner" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>
        {{ 'sendEmail' | i18n }}
      </span>
    </button>
  </div>
</div>
