<div *ngIf="selfHosted" class="page-header">
  <h1>{{ 'subscription' | i18n }}</h1>
</div>
<div *ngIf="!selfHosted" class="tabbed-header">
  <h1>{{ 'goPremium' | i18n }}</h1>
</div>
<bit-callout type="info" *ngIf="canAccessPremium" title="{{ 'youHavePremiumAccess' | i18n }}" icon="bwi bwi-star-f">
  {{ 'alreadyPremiumFromOrg' | i18n }}
</bit-callout>
<bit-callout type="success">
  <p>{{ 'premiumUpgradeUnlockFeatures' | i18n }}</p>
  <ul class="bwi-ul">
    <li>
      <i class="bwi bwi-check text-success bwi-li" aria-hidden="true"></i>
      {{ 'premiumSignUpStorage' | i18n }}
    </li>
    <li>
      <i class="bwi bwi-check text-success bwi-li" aria-hidden="true"></i>
      {{ 'premiumSignUpTwoStep' | i18n }}
    </li>
    <li>
      <i class="bwi bwi-check text-success bwi-li" aria-hidden="true"></i>
      {{ 'premiumSignUpEmergency' | i18n }}
    </li>
    <li>
      <i class="bwi bwi-check text-success bwi-li" aria-hidden="true"></i>
      {{ 'premiumSignUpReports' | i18n }}
    </li>
    <li>
      <i class="bwi bwi-check text-success bwi-li" aria-hidden="true"></i>
      {{ 'premiumSignUpTotp' | i18n }}
    </li>
    <li>
      <i class="bwi bwi-check text-success bwi-li" aria-hidden="true"></i>
      {{ 'premiumSignUpSupport' | i18n }}
    </li>
    <li>
      <i class="bwi bwi-check text-success bwi-li" aria-hidden="true"></i>
      {{ 'premiumSignUpFuture' | i18n }}
    </li>
  </ul>
  <p class="text-lg" [ngClass]="{ 'mb-0': !selfHosted }">
    {{ 'premiumPrice' | i18n: (premiumPrice | currency: '$') }}
  </p>
  <a bitButton href="https://vault.bitwarden.com/#/settings/premium" target="_blank" rel="noopener" buttonType="secondary" *ngIf="selfHosted">
    {{ 'purchasePremium' | i18n }}
  </a>
</bit-callout>
<ng-container *ngIf="selfHosted">
  <p>{{ 'uploadLicenseFilePremium' | i18n }}</p>
  <form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
    <div class="form-group">
      <label for="file">{{ 'licenseFile' | i18n }}</label>
      <input type="file" id="file" class="form-control-file" name="file" required />
      <small class="form-text text-muted">{{ 'licenseFileDesc' | i18n: 'bitwarden_premium_license.json' }}</small>
    </div>
    <button bitButton buttonType="primary" type="submit" class="btn-submit" [disabled]="form.loading">
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ 'submit' | i18n }}</span>
    </button>
  </form>
</ng-container>
<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate *ngIf="!selfHosted">
  <h2 class="mt-5">{{ 'addons' | i18n }}</h2>
  <div class="row">
    <div class="form-group col-6">
      <label for="additionalStorage">{{ 'additionalStorageGb' | i18n }}</label>
      <input
        id="additionalStorage"
        class="form-control"
        type="number"
        name="AdditionalStorageGb"
        [(ngModel)]="additionalStorage"
        min="0"
        max="99"
        step="1"
        placeholder="{{ 'additionalStorageGbDesc' | i18n }}"
      />
      <small class="text-muted form-text">{{
        'additionalStorageIntervalDesc' | i18n: '1 GB':(storageGbPrice | currency: '$'):('year' | i18n)
      }}</small>
    </div>
  </div>
  <h2 class="spaced-header">{{ 'summary' | i18n }}</h2>
  {{ 'premiumMembership' | i18n }}: {{ premiumPrice | currency: '$' }} <br />
  {{ 'additionalStorageGb' | i18n }}: {{ additionalStorage || 0 }} GB &times; {{ storageGbPrice | currency: '$' }} =
  {{ additionalStorageTotal | currency: '$' }}
  <hr class="my-3" />
  <h2 class="spaced-header mb-4">{{ 'paymentInformation' | i18n }}</h2>
  <app-payment [hideBank]="true"></app-payment>
  <app-tax-info></app-tax-info>
  <div id="price" class="my-4">
    <div class="text-muted text-sm">
      {{ 'planPrice' | i18n }}: {{ subtotal | currency: 'USD $' }}
      <br />
      <ng-container> {{ 'estimatedTax' | i18n }}: {{ taxCharges | currency: 'USD $' }} </ng-container>
    </div>
    <hr class="my-1 col-3 ml-0" />
    <p class="text-lg">
      <strong>{{ 'total' | i18n }}:</strong> {{ total | currency: 'USD $' }}/{{ 'year' | i18n }}
    </p>
  </div>
  <small class="text-muted font-italic">{{ 'paymentChargedAnnually' | i18n }}</small>
  <button bitButton buttonType="primary" type="submit" class="btn-submit" [disabled]="form.loading">
    <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
    <span>{{ 'submit' | i18n }}</span>
  </button>
</form>
