<nav class="navbar navbar-expand navbar-dark" [ngClass]="{ 'nav-background-alt': selfHosted }">
  <div class="container-fluid page-section">
    <a class="navbar-brand" routerLink="/" appA11yTitle="{{ 'pageTitle' | i18n: 'SUPASS' }}">
      <img src="./images/su.svg" alt="SUPASS" />
    </a>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav ml-md-auto">
        <li class="nav-item -featured" routerLinkActive="active">
          <a class="nav-link" routerLink="/vault">
            <span>
              {{ 'myVault' | i18n }}
            </span>
            <svg-icon key="lock-su"></svg-icon>
          </a>
        </li>
        <!--        <li class="nav-item" routerLinkActive="active">-->
        <!--          <a class="nav-link" routerLink="/sends">{{ 'send' | i18n }}</a>-->
        <!--        </li>-->
        <li class="nav-item -featured" routerLinkActive="active">
          <a class="nav-link" routerLink="/tools">
            <span>
              {{ 'tools' | i18n }}
            </span>
            <svg-icon key="tools-su"></svg-icon>
          </a>
        </li>
        <li class="nav-item -featured" routerLinkActive="active">
          <a class="nav-link" routerLink="/settings">
            <span>
              {{ 'settings' | i18n }}
            </span>
            <svg-icon key="cog-su"></svg-icon>
          </a>
        </li>
        <!--        <li class="nav-item -featured" routerLinkActive="active">-->
        <!--          <a class="nav-link" routerLink="/reports">{{ 'reports' | i18n }}</a>-->
        <!--        </li>-->
        <!--        <li *ngIf="organizations.length >= 1" class="nav-item" routerLinkActive="active">-->
        <!--          <a class="nav-link" [routerLink]="['/organizations', organizations[0].id]">{{ 'organizations' | i18n }}</a>-->
        <!--        </li>-->
        <ng-container *ngIf="providers.length >= 1">
          <li class="nav-item -featured" routerLinkActive="active" *ngIf="providers.length == 1">
            <a class="nav-link" [routerLink]="['/providers', providers[0].id]">{{ 'provider' | i18n }}</a>
          </li>
          <li class="nav-item -featured" routerLinkActive="active" *ngIf="providers.length > 1">
            <a class="nav-link" routerLink="/providers">{{ 'provider' | i18n }}</a>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="navbar-divider"></div>
    <ul class="navbar-nav flex-row ml-md-auto d-none d-md-flex">
      <li>
        <button
          id="nav-profile"
          [bitMenuTriggerFor]="accountMenu"
          class="tw-border-0 tw-bg-transparent tw-text-alt2 tw-opacity-70 hover:tw-opacity-90"
        >
          <svg-icon key="user-su"></svg-icon>
        </button>
        <!--        <a [bitMenuTriggerFor]="accountMenu" class="tw-border-0 tw-bg-transparent tw-text-alt2 tw-opacity-70 hover:tw-opacity-90"-->
        <!--          id="nav-profile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--          <svg-icon key="user-su"></svg-icon>-->
        <!--        </a>-->
        <bit-menu class="dropdown-menu" #accountMenu>
          <div class="tw-max-w-[300px] tw-min-w-[200px] tw-flex tw-flex-col">
            <div class="tw-flex tw-items-center tw-leading-tight tw-text-info tw-py-1 tw-px-4" *ngIf="name" appStopProp>
              <app-avatar [data]="name" [email]="email" size="25" fontSize="14" [circle]="true"></app-avatar>
              <div class="tw-ml-2 tw-block tw-overflow-hidden tw-whitespace-nowrap">
                <span>{{ 'loggedInAs' | i18n }}</span>
                <small class="tw-text-muted tw-block tw-overflow-hidden tw-whitespace-nowrap">{{ name }}</small>
              </div>
            </div>
            <bit-menu-divider></bit-menu-divider>
            <a bitMenuItem routerLink="/settings/account">
              <i class="bwi bwi-fw bwi-user" aria-hidden="true"></i>
              {{ 'accountSettings' | i18n }}
            </a>
            <a bitMenuItem href="https://bitwarden.com/help/" target="_blank" rel="noopener">
              <i class="bwi bwi-fw bwi-question-circle" aria-hidden="true"></i>
              {{ 'getHelp' | i18n }}
            </a>
            <a bitMenuItem href="https://bitwarden.com/download/" target="_blank" rel="noopener">
              <i class="bwi bwi-fw bwi-download" aria-hidden="true"></i>
              {{ 'getApps' | i18n }}
            </a>
            <bit-menu-divider></bit-menu-divider>
            <button bitMenuItem type="button" (click)="lock()">
              <i class="bwi bwi-fw bwi-lock" aria-hidden="true"></i>
              {{ 'lockNow' | i18n }}
            </button>
            <button bitMenuItem type="button" (click)="logOut()">
              <i class="bwi bwi-fw bwi-sign-out" aria-hidden="true"></i>
              {{ 'logOut' | i18n }}
            </button>
          </div>
        </bit-menu>
      </li>
    </ul>
  </div>
</nav>
