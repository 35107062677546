<div class="form-group mb-0">
  <div class="form-check mt-1 form-check-block">
    <input
      class="form-check-input"
      type="checkbox"
      [name]="pascalize(parentId)"
      [id]="parentId"
      [(ngModel)]="parentChecked"
      [indeterminate]="parentIndeterminate"
    />
    <label class="form-check-label font-weight-normal" [for]="parentId">
      {{ parentId | i18n }}
    </label>
  </div>
  <div class="form-group form-group-child-check mb-0">
    <div class="form-check mt-1" *ngFor="let c of checkboxes">
      <input class="form-check-input" type="checkbox" [name]="pascalize(c.id)" [id]="c.id" [ngModel]="c.get()" (ngModelChange)="c.set($event)" />
      <label class="form-check-label font-weight-normal" [for]="c.id">
        {{ c.id | i18n }}
      </label>
    </div>
  </div>
</div>
