<div class="page-header d-flex">
  <h1>{{ 'eventLogs' | i18n }}</h1>
  <div class="ml-auto d-flex">
    <div class="form-inline">
      <label class="sr-only" for="start">{{ 'startDate' | i18n }}</label>
      <input
        type="datetime-local"
        class="form-control form-control-sm"
        id="start"
        placeholder="{{ 'startDate' | i18n }}"
        [(ngModel)]="start"
        placeholder="YYYY-MM-DDTHH:MM"
        (change)="dirtyDates = true"
      />
      <span class="mx-2">-</span>
      <label class="sr-only" for="end">{{ 'endDate' | i18n }}</label>
      <input
        type="datetime-local"
        class="form-control form-control-sm"
        id="end"
        placeholder="{{ 'endDate' | i18n }}"
        [(ngModel)]="end"
        placeholder="YYYY-MM-DDTHH:MM"
        (change)="dirtyDates = true"
      />
    </div>
    <form #refreshForm [appApiAction]="refreshPromise" class="d-inline">
      <button type="button" class="btn btn-sm btn-outline-primary ml-3" (click)="loadEvents(true)" [disabled]="loaded && refreshForm.loading">
        <i class="bwi bwi-refresh bwi-fw" aria-hidden="true" [ngClass]="{ 'bwi-spin': loaded && refreshForm.loading }"></i>
        {{ 'refresh' | i18n }}
      </button>
    </form>
    <form #exportForm [appApiAction]="exportPromise" class="d-inline">
      <button
        type="button"
        class="btn btn-sm btn-outline-primary btn-submit manual ml-3"
        [ngClass]="{ loading: exportForm.loading }"
        (click)="exportEvents()"
        [disabled]="(loaded && exportForm.loading) || dirtyDates"
      >
        <i class="bwi bwi-spinner bwi-spin" aria-hidden="true"></i>
        <span>{{ 'export' | i18n }}</span>
      </button>
    </form>
  </div>
</div>
<ng-container *ngIf="!loaded">
  <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
  <span class="sr-only">{{ 'loading' | i18n }}</span>
</ng-container>
<ng-container *ngIf="loaded">
  <p *ngIf="!events || !events.length">{{ 'noEventsInList' | i18n }}</p>
  <table class="table table-hover" *ngIf="events && events.length">
    <thead>
      <tr>
        <th class="border-top-0" width="210">{{ 'timestamp' | i18n }}</th>
        <th class="border-top-0" width="40">
          <span class="sr-only">{{ 'device' | i18n }}</span>
        </th>
        <th class="border-top-0" width="150">{{ 'user' | i18n }}</th>
        <th class="border-top-0">{{ 'event' | i18n }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let e of events">
        <td>{{ e.date | date: 'medium' }}</td>
        <td>
          <i class="text-muted bwi bwi-lg {{ e.appIcon }}" title="{{ e.appName }}, {{ e.ip }}" aria-hidden="true"></i>
          <span class="sr-only">{{ e.appName }}, {{ e.ip }}</span>
        </td>
        <td>
          <span title="{{ e.userEmail }}">{{ e.userName }}</span>
        </td>
        <td [innerHTML]="e.message"></td>
      </tr>
    </tbody>
  </table>
  <button
    #moreBtn
    [appApiAction]="morePromise"
    type="button"
    class="btn btn-block btn-link btn-submit"
    (click)="loadEvents(false)"
    [disabled]="loaded && moreBtn.loading"
    *ngIf="continuationToken"
  >
    <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
    <span>{{ 'loadMore' | i18n }}</span>
  </button>
</ng-container>
