<div class="page-header d-flex">
  <h1>{{ 'people' | i18n }}</h1>
  <div class="ml-auto d-flex">
    <div class="btn-group btn-group-sm" role="group">
      <button type="button" class="btn btn-outline-primary" [ngClass]="{ active: status == null }" (click)="filter(null)">
        {{ 'all' | i18n }}
        <span class="badge badge-pill badge-info" *ngIf="allCount">{{ allCount }}</span>
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        [ngClass]="{ active: status == userStatusType.Invited }"
        (click)="filter(userStatusType.Invited)"
      >
        {{ 'invited' | i18n }}
        <span class="badge badge-pill badge-info" *ngIf="invitedCount">{{ invitedCount }}</span>
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        [ngClass]="{ active: status == userStatusType.Accepted }"
        (click)="filter(userStatusType.Accepted)"
      >
        {{ 'accepted' | i18n }}
        <span class="badge badge-pill badge-warning" *ngIf="acceptedCount">{{ acceptedCount }}</span>
      </button>
    </div>
    <div class="ml-3">
      <label class="sr-only" for="search">{{ 'search' | i18n }}</label>
      <input type="search" class="form-control form-control-sm" id="search" placeholder="{{ 'search' | i18n }}" [(ngModel)]="searchText" />
    </div>
    <div class="dropdown ml-3" appListDropdown>
      <button
        class="btn btn-sm btn-outline-primary dropdown-toggle"
        type="button"
        id="bulkActionsButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        appA11yTitle="{{ 'options' | i18n }}"
      >
        <i class="bwi bwi-cog" aria-hidden="true"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="bulkActionsButton">
        <button class="dropdown-item" appStopClick (click)="bulkReinvite()">
          <i class="bwi bwi-fw bwi-envelope" aria-hidden="true"></i>
          {{ 'reinviteSelected' | i18n }}
        </button>
        <button class="dropdown-item text-success" appStopClick (click)="bulkConfirm()" *ngIf="showBulkConfirmUsers">
          <i class="bwi bwi-fw bwi-check" aria-hidden="true"></i>
          {{ 'confirmSelected' | i18n }}
        </button>
        <button class="dropdown-item text-danger" appStopClick (click)="bulkRemove()">
          <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
          {{ 'remove' | i18n }}
        </button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item" appStopClick (click)="selectAll(true)">
          <i class="bwi bwi-fw bwi-check-square" aria-hidden="true"></i>
          {{ 'selectAll' | i18n }}
        </button>
        <button class="dropdown-item" appStopClick (click)="selectAll(false)">
          <i class="bwi bwi-fw bwi-minus-square" aria-hidden="true"></i>
          {{ 'unselectAll' | i18n }}
        </button>
      </div>
    </div>
    <button type="button" class="btn btn-sm btn-outline-primary-primary ml-3" (click)="invite()">
      <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
      {{ 'inviteUser' | i18n }}
    </button>
  </div>
</div>
<ng-container *ngIf="loading">
  <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
  <span class="sr-only">{{ 'loading' | i18n }}</span>
</ng-container>
<ng-container *ngIf="!loading && (isPaging() ? pagedUsers : (users | search: searchText:'name':'email':'id')) as searchedUsers">
  <p *ngIf="!searchedUsers.length">{{ 'noUsersInList' | i18n }}</p>
  <ng-container *ngIf="searchedUsers.length">
    <app-callout type="info" title="{{ 'confirmUsers' | i18n }}" icon="bwi bwi-check-circle" *ngIf="showConfirmUsers">
      {{ 'usersNeedConfirmed' | i18n }}
    </app-callout>
    <table
      class="table table-hover table-list"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollDisabled]="!isPaging()"
      (scrolled)="loadMore()"
    >
      <tbody>
        <tr *ngFor="let u of searchedUsers">
          <td (click)="checkUser(u)" class="table-list-checkbox">
            <input type="checkbox" [(ngModel)]="u.checked" appStopProp />
          </td>
          <td width="30">
            <app-avatar [data]="u | userName" [email]="u.email" size="25" [circle]="true" [fontSize]="14"> </app-avatar>
          </td>
          <td>
            <a href="#" appStopClick (click)="edit(u)">{{ u.email }}</a>
            <span class="badge badge-secondary" *ngIf="u.status === userStatusType.Invited">{{ 'invited' | i18n }}</span>
            <span class="badge badge-warning" *ngIf="u.status === userStatusType.Accepted">{{ 'accepted' | i18n }}</span>
            <small class="text-muted d-block" *ngIf="u.name">{{ u.name }}</small>
          </td>
          <td>
            <ng-container *ngIf="u.twoFactorEnabled">
              <i class="fa fa-lock" title="{{ 'userUsingTwoStep' | i18n }}" aria-hidden="true"></i>
              <span class="sr-only">{{ 'userUsingTwoStep' | i18n }}</span>
            </ng-container>
            <ng-container *ngIf="showEnrolledStatus(u)">
              <i class="bwi bwi-key" title="{{ 'enrolledPasswordReset' | i18n }}" aria-hidden="true"></i>
              <span class="sr-only">{{ 'enrolledPasswordReset' | i18n }}</span>
            </ng-container>
          </td>
          <td>
            <span *ngIf="u.type === userType.Owner">{{ 'owner' | i18n }}</span>
            <span *ngIf="u.type === userType.Admin">{{ 'admin' | i18n }}</span>
            <span *ngIf="u.type === userType.Manager">{{ 'manager' | i18n }}</span>
            <span *ngIf="u.type === userType.User">{{ 'user' | i18n }}</span>
            <span *ngIf="u.type === userType.Custom">{{ 'custom' | i18n }}</span>
          </td>
          <td class="table-list-options">
            <div class="dropdown" appListDropdown>
              <button
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                appA11yTitle="{{ 'options' | i18n }}"
              >
                <i class="bwi bwi-cog bwi-lg" aria-hidden="true"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#" appStopClick (click)="reinvite(u)" *ngIf="u.status === userStatusType.Invited">
                  <i class="bwi bwi-fw bwi-envelope" aria-hidden="true"></i>
                  {{ 'resendInvitation' | i18n }}
                </a>
                <a class="dropdown-item text-success" href="#" appStopClick (click)="confirm(u)" *ngIf="u.status === userStatusType.Accepted">
                  <i class="bwi bwi-fw bwi-check" aria-hidden="true"></i>
                  {{ 'confirm' | i18n }}
                </a>
                <a class="dropdown-item" href="#" appStopClick (click)="groups(u)" *ngIf="accessGroups">
                  <i class="bwi bwi-fw bwi-sitemap" aria-hidden="true"></i>
                  {{ 'groups' | i18n }}
                </a>
                <a class="dropdown-item" href="#" appStopClick (click)="events(u)" *ngIf="accessEvents && u.status === userStatusType.Confirmed">
                  <i class="bwi bwi-fw bwi-file-text" aria-hidden="true"></i>
                  {{ 'eventLogs' | i18n }}
                </a>
                <a class="dropdown-item" href="#" appStopClick (click)="resetPassword(u)" *ngIf="allowResetPassword(u)">
                  <i class="bwi bwi-fw bwi-key" aria-hidden="true"></i>
                  {{ 'resetPassword' | i18n }}
                </a>
                <a class="dropdown-item text-danger" href="#" appStopClick (click)="remove(u)">
                  <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
                  {{ 'remove' | i18n }}
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>
<ng-template #addEdit></ng-template>
<ng-template #groupsTemplate></ng-template>
<ng-template #eventsTemplate></ng-template>
<ng-template #confirmTemplate></ng-template>
<ng-template #resetPasswordTemplate></ng-template>
<ng-template #bulkStatusTemplate></ng-template>
<ng-template #bulkConfirmTemplate></ng-template>
<ng-template #bulkRemoveTemplate></ng-template>
