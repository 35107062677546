<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="attachmentsTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
      <div class="modal-header">
        <h2 class="modal-title" id="attachmentsTitle">
          {{ 'attachments' | i18n }}
          <small *ngIf="cipher">{{ cipher.name }}</small>
        </h2>
        <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{ 'close' | i18n }}">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-hover table-list" *ngIf="cipher && cipher.hasAttachments">
          <tbody>
            <tr *ngFor="let a of cipher.attachments">
              <td class="table-list-icon">
                <i class="bwi bwi-fw bwi-lg bwi-file" *ngIf="!a.downloading" aria-hidden="true"></i>
                <i class="bwi bwi-spinner bwi-lg bwi-fw bwi-spin" *ngIf="a.downloading" aria-hidden="true"></i>
              </td>
              <td class="wrap">
                <div class="d-flex">
                  <a href="#" appStopClick (click)="download(a)">{{ a.fileName }}</a>
                  <div *ngIf="showFixOldAttachments(a)" class="ml-2">
                    <a href="https://bitwarden.com/help/attachments/#fixing-old-attachments" target="_blank" rel="noopener">
                      <i class="bwi bwi-exclamation-triangle text-warning" title="{{ 'attachmentFixDesc' | i18n }}" aria-hidden="true"></i>
                      <span class="sr-only">{{ 'attachmentFixDesc' | i18n }}</span></a
                    >
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm m-0 py-0 px-2"
                      (click)="reupload(a)"
                      #reuploadBtn
                      [appApiAction]="reuploadPromises[a.id]"
                      [disabled]="reuploadBtn.loading"
                    >
                      {{ 'fix' | i18n }}
                    </button>
                  </div>
                </div>
                <small>{{ a.sizeName }}</small>
              </td>
              <td class="table-list-options" *ngIf="!viewOnly">
                <button
                  class="btn btn-outline-danger"
                  type="button"
                  appStopClick
                  appA11yTitle="{{ 'delete' | i18n }}"
                  (click)="delete(a)"
                  #deleteBtn
                  [appApiAction]="deletePromises[a.id]"
                  [disabled]="deleteBtn.loading"
                >
                  <i class="bwi bwi-trash bwi-lg bwi-fw" [hidden]="deleteBtn.loading" aria-hidden="true"></i>
                  <i
                    class="bwi bwi-spinner bwi-spin bwi-lg bwi-fw"
                    [hidden]="!deleteBtn.loading"
                    title="{{ 'loading' | i18n }}"
                    aria-hidden="true"
                  ></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="!viewOnly">
          <h3>{{ 'newAttachment' | i18n }}</h3>
          <label for="file" class="sr-only">{{ 'file' | i18n }}</label>
          <input type="file" id="file" class="form-control-file" name="file" required />
          <small class="form-text text-muted">{{ 'maxFileSize' | i18n }}</small>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading" *ngIf="!viewOnly">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ 'save' | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ 'close' | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
